import { solution } from '../../lib/melodies'
import { Cell } from './Cell'
import { RowContainer } from './RowContainer'
import { RowPlayButton } from './RowPlayButton'

export const EmptyRow = ({ isDarkMode }: { isDarkMode: boolean }) => {
  const emptyCells = Array.from(Array(solution.length))

  return (
    <div className="mb-1 flex justify-center">
      <RowContainer>
        <RowPlayButton onPlay={() => {}} isDisabled />
        {emptyCells.map((_, i) => (
          <Cell key={i} isDarkMode={isDarkMode} />
        ))}
      </RowContainer>
    </div>
  )
}
