export const LumiLogo = () => {
  return (
    <svg
      width="60"
      height="16"
      viewBox="0 0 60 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="fill-logo-text dark:fill-dark-logo-text">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.1969 0.185478C53.3764 0.197205 53.5287 0.320963 53.5769 0.494047L56.3298 10.5003C56.4051 10.7725 56.3689 11.0635 56.2291 11.3089C56.0894 11.5544 55.8576 11.7342 55.585 11.8086C55.0173 11.9645 54.4306 11.6314 54.2741 11.0644L52.8321 5.81348L50.2639 11.8979C50.1977 12.0542 50.0443 12.1557 49.8744 12.1557C49.7045 12.1557 49.5511 12.0542 49.4849 11.8979L46.9167 5.81348L45.4747 11.0644C45.3182 11.6314 44.7315 11.9645 44.1639 11.8086C43.8912 11.7342 43.6594 11.5544 43.5197 11.3089C43.3799 11.0635 43.3437 10.7725 43.419 10.5003L46.1659 0.495535C46.2141 0.322451 46.3665 0.198694 46.5459 0.186966C46.7253 0.175238 46.8926 0.278103 46.9629 0.443442L49.8692 7.33756L52.78 0.441954C52.8503 0.276615 53.0175 0.17375 53.1969 0.185478ZM24.4292 0.317583V9.69837C24.4288 10.3147 24.6672 10.9059 25.0918 11.3418C25.5164 11.7778 26.0925 12.0227 26.6932 12.0227H32.2483V9.79389H27.2237C26.9052 9.79389 26.6467 9.52939 26.6459 9.20257V0.317583H24.4292ZM32.9109 6.99147C32.9109 10.118 35.1585 12.0227 37.7467 12.0227C40.3408 12.0227 42.5899 10.118 42.5854 6.99896V0.317583H40.3542V6.90612C40.3542 8.73893 39.2832 9.92487 37.7482 9.92487C36.2131 9.92487 35.1421 8.73893 35.1421 6.90612V0.317583H32.9109V6.99147ZM57.4284 10.8857V0.317583H59.5488V10.8857C59.5488 11.5137 59.0742 12.0227 58.4886 12.0227C57.9053 12.0194 57.4341 11.5113 57.4341 10.8857H57.4284Z"
        />
        <path d="M8.304 8.08838C8.304 5.78438 6.448 3.86438 4.176 3.86438C3.28 3.86438 2.512 4.16838 1.936 4.63238V0.984375H0V12.1844H1.936V11.5444C2.512 12.0084 3.28 12.3124 4.176 12.3124C6.448 12.3124 8.304 10.3924 8.304 8.08838ZM6.368 8.08838C6.368 9.44838 5.408 10.5364 4.144 10.5364C2.88 10.5364 1.936 9.44838 1.936 8.08838C1.936 6.72837 2.88 5.64038 4.144 5.64038C5.408 5.64038 6.368 6.72837 6.368 8.08838Z" />
        <path d="M17.8988 3.99238H15.7548L13.4348 8.77638L11.0988 3.99238H8.97075L12.3787 10.8564L10.1547 15.4164H12.2828L17.8988 3.99238Z" />
      </g>
    </svg>
  )
}
