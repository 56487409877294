const gameStateKey = 'gameState'
const archiveGameStateKey = 'archiveGameState'
export const themeKey = 'theme'
const highContrastKey = 'isHighContrast'

export type StoredGameState = {
  guesses: number[][]
  solution: number[]
  startedAt: string | null
  completedAt: string | null
}

export const saveGameStateToLocalStorage = (
  isLatestGame: boolean,
  gameState: StoredGameState
) => {
  const key = isLatestGame ? gameStateKey : archiveGameStateKey
  localStorage.setItem(key, JSON.stringify(gameState))
}

export const loadGameStateFromLocalStorage = (isLatestGame: boolean) => {
  const key = isLatestGame ? gameStateKey : archiveGameStateKey
  const state = localStorage.getItem(key)
  return state ? (JSON.parse(state) as StoredGameState) : null
}

const gameStatKey = 'gameStats'

export type GameStats = {
  winDistribution: number[]
  gamesFailed: number
  currentStreak: number
  bestStreak: number
  totalGames: number
  successRate: number
  lastCompletedDate: string | null
}

export const saveStatsToLocalStorage = (gameStats: GameStats) => {
  localStorage.setItem(gameStatKey, JSON.stringify(gameStats))
}

export const loadStatsFromLocalStorage = () => {
  const stats = localStorage.getItem(gameStatKey)
  return stats ? (JSON.parse(stats) as GameStats) : null
}

export const setStoredIsDarkMode = (isDarkMode: boolean) => {
  localStorage.setItem(themeKey, isDarkMode ? 'dark' : 'light')
}

export const getStoredIsDarkMode = () => {
  const isDarkMode = localStorage.getItem(themeKey)
  return isDarkMode === 'dark'
}

export const getStoredIsHighContrastMode = () => {
  const isHighContrastMode = localStorage.getItem(highContrastKey)
  return isHighContrastMode === '1'
}

export const setStoredIsHighContrastMode = (isHighContrastMode: boolean) => {
  if (isHighContrastMode) {
    localStorage.setItem(highContrastKey, '1')
  } else {
    localStorage.removeItem(highContrastKey)
  }
}
