import classnames from 'classnames'
import { useCallback, useState } from 'react'

import { PLAYBACK_RATE, PLAYBACK_RATE_HARD } from '../../constants/settings'
import { NOTES, getPlaybackRateMs, playMelody } from '../../lib/audio'
import { solution } from '../../lib/melodies'
import { Cell } from './Cell'
import { RowContainer } from './RowContainer'
import { RowPlayButton } from './RowPlayButton'

type Props = {
  guess: number[]
  className: string
  showStartingNote: boolean
  isDarkMode: boolean
  isHardMode: boolean
}

export const CurrentRow = ({
  guess,
  className,
  showStartingNote,
  isDarkMode,
  isHardMode,
}: Props) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const emptyCells = Array.from(Array(solution.length - guess.length))
  const classes = `flex justify-center mb-1 ${className}`
  const playbackRate = getPlaybackRateMs(isHardMode)

  const onPlay = useCallback(() => {
    setIsPlaying(true)
    playMelody(guess, isHardMode ? PLAYBACK_RATE_HARD : PLAYBACK_RATE, () => {
      setIsPlaying(false)
    })
  }, [guess, isHardMode])

  return (
    <div className={classes}>
      <RowContainer>
        <RowPlayButton
          onPlay={onPlay}
          isDisabled={guess.length === 0}
          isPlaying={isPlaying}
        />
        {showStartingNote && (
          <Cell value={NOTES[solution[0]]} isGuide isDarkMode={isDarkMode} />
        )}
        {guess.map((noteIndex, i) => (
          <div
            key={i}
            className={classnames({ 'animation-pop': isPlaying })}
            style={{
              animationDelay: `${i * playbackRate}ms`,
            }}
          >
            <Cell key={i} value={NOTES[noteIndex]} isDarkMode={isDarkMode} />
          </div>
        ))}
        {emptyCells.map((_, i, arr) => {
          if (showStartingNote && i === arr.length - 1) return null
          return <Cell key={i} isDarkMode={isDarkMode} />
        })}
      </RowContainer>
    </div>
  )
}
