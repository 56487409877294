import classnames from 'classnames'

type Props = {
  onClick?: () => void
  className: string
}

export const StatsIcon = ({ onClick, className }: Props) => {
  return (
    <div onClick={onClick} className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="fill-nav-icon dark:fill-dark-nav-icon">
          <rect x="13" y="20" width="4" height="16" rx="2" />
          <rect x="31" y="20" width="4" height="16" rx="2" />
          <rect x="25" y="16" width="4" height="20" rx="2" />
          <rect x="19" y="12" width="4" height="24" rx="2" />
        </g>
      </svg>
    </div>
  )
}
