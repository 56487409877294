import classNames from 'classnames'
import { useState } from 'react'

import { PLAYBACK_RATE, PLAYBACK_RATE_HARD } from '../../constants/settings'
import { PLAY_BUTTON_TEXT, REPLAY_BUTTON_TEXT } from '../../constants/strings'
import { NOTES, getPlaybackRateMs } from '../../lib/audio'
import { solution, unicodeSplit } from '../../lib/melodies'
import { getGuessStatuses } from '../../lib/statuses'
import { PlayIcon } from '../icons/PlayIcon'
import { Cell } from './Cell'
import { RowContainer } from './RowContainer'
import { RowPlayButton } from './RowPlayButton'

type Props = {
  onPlay: () => void
  isPlaying: boolean
  hasPlayedMelody: boolean
  isLumiMode: boolean
  isHardMode: boolean
  showSolution: boolean
  solution: number[]
}

export const MelodyPlayButton = ({
  onPlay,
  isPlaying,
  hasPlayedMelody,
  isLumiMode,
  isHardMode,
  showSolution,
  solution,
}: Props) => {
  const buttonText = hasPlayedMelody ? REPLAY_BUTTON_TEXT : PLAY_BUTTON_TEXT
  const playbackRate = getPlaybackRateMs(isHardMode)
  return (
    <div className="relative mb-2 flex w-full font-bold mdshort:mb-1">
      {isPlaying && (
        <RowContainer isMainPlayButton>
          <div>
            <RowPlayButton onPlay={() => {}} isPlaying isMainPlayButton />
          </div>
          {solution.map((value, i) => {
            const showNote = (i === 0 && !isHardMode) || showSolution
            return (
              <Cell
                key={i}
                isDarkMode={isLumiMode}
                value={showNote ? NOTES[value] : ''}
                isInsidePlayButton
                isGuide={showNote}
                status={showNote ? 'correct' : undefined}
                animationDelay={`${i * playbackRate}ms`}
                className={'animation-pop'}
              />
            )
          })}
        </RowContainer>
      )}
      {!isPlaying && showSolution && (
        <RowContainer isMainPlayButton>
          <div>
            <RowPlayButton
              onPlay={() => {
                if (showSolution) {
                  onPlay()
                }
              }}
              isPlaying={isPlaying}
              isMainPlayButton
            />
          </div>
          {solution.map((value, i) => {
            return (
              <Cell
                key={i}
                isDarkMode={isLumiMode}
                value={NOTES[value]}
                isInsidePlayButton
                isGuide
                status={'correct'}
              />
            )
          })}
        </RowContainer>
      )}
      {!isPlaying && !showSolution && (
        <div
          onClick={() => {
            if (!isPlaying) {
              onPlay()
            }
          }}
          className={classNames(
            'rounded-4xl dark:bg-dark-button flex h-12 w-full transform cursor-pointer content-center items-center justify-center text-center align-middle text-base font-bold transition hover:scale-105 md:h-14 md:text-xl short:h-10 short:text-sm',
            // { 'animate-glow': !hasPlayedMelody && !isPlaying },
            {
              'border-2 border-play-button-active-border text-play-button-active-text dark:border-dark-play-button-active-border dark:text-dark-play-button-active-text':
                hasPlayedMelody || isPlaying,
            },
            {
              'bg-play-button text-play-button-text dark:bg-dark-play-button dark:text-dark-play-button-text':
                !hasPlayedMelody,
            }
          )}
        >
          <div className="pointer-events-none absolute left-0 ml-1 h-11 w-11 md:h-12 md:w-12 short:h-8 short:w-8">
            <PlayIcon
              invertedColor={!hasPlayedMelody}
              isPlaying={isPlaying}
              isMainPlayButton
              hasPlayedMelody={hasPlayedMelody}
            />
          </div>
          <div className="p-2">{buttonText + ' '}</div>
        </div>
      )}
    </div>
  )
}
