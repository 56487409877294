import { UAParser } from 'ua-parser-js'

import { MAX_CHALLENGES } from '../constants/settings'
import { GAME_TITLE, SHARE_URL } from '../constants/strings'
import { getTimeTaken } from './dateutils'
import { solutionIndex } from './melodies'
import { getGuessStatuses } from './statuses'

const webShareApiDeviceTypes: string[] = ['mobile', 'smarttv', 'wearable']
const parser = new UAParser()
const browser = parser.getBrowser()
const device = parser.getDevice()

export const shareStatus = (
  solution: number[],
  guesses: number[][],
  startedAt: string | null,
  completedAt: string | null,
  lost: boolean,
  isHardMode: boolean,
  isDarkMode: boolean,
  isHighContrastMode: boolean,
  handleShareToClipboard: () => void,
  handleShareFailure: () => void
) => {
  const timeTaken = Boolean(startedAt && completedAt)
    ? getTimeTaken(new Date(startedAt!), new Date(completedAt!))
    : ''
  const appReleasedOnDay = 54
  const keydleDay = solutionIndex + 1 - appReleasedOnDay
  const textToShare =
    `${GAME_TITLE} by LUMI #${keydleDay}\n${
      lost ? 'X' : guesses.length
    }/${MAX_CHALLENGES}${
      isHardMode ? ' (hard mode)' : ''
    } – ${timeTaken}\n${SHARE_URL}\n` +
    generateEmojiGrid(
      solution,
      guesses,
      getEmojiTiles(isDarkMode, isHighContrastMode)
    )

  const shareData = { text: textToShare }

  let shareSuccess = false

  try {
    if (attemptShare(shareData)) {
      navigator.share(shareData)
      shareSuccess = true
    }
  } catch (error) {
    shareSuccess = false
  }

  try {
    if (!shareSuccess) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(textToShare)
          .then(handleShareToClipboard)
          .catch(handleShareFailure)
      } else {
        handleShareFailure()
      }
    }
  } catch (error) {
    handleShareFailure()
  }
}

export const generateEmojiGrid = (
  solution: number[],
  guesses: number[][],
  tiles: string[]
) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(solution, guess)

      return guess
        .map((_, i) => {
          switch (status[i]) {
            case 'correct':
              return tiles[0]
            case 'present':
              return tiles[1]
            default:
              return tiles[2]
          }
        })
        .join('')
    })
    .join('\n')
}

const attemptShare = (shareData: object) => {
  return (
    // Deliberately exclude Firefox Mobile, because its Web Share API isn't working correctly
    browser.name?.toUpperCase().indexOf('FIREFOX') === -1 &&
    webShareApiDeviceTypes.indexOf(device.type ?? '') !== -1 &&
    navigator.canShare &&
    navigator.canShare(shareData) &&
    navigator.share
  )
}

const getEmojiTiles = (isDarkMode: boolean, isHighContrastMode: boolean) => {
  let tiles: string[] = []
  tiles.push(isHighContrastMode ? '🟠' : '🟢')
  tiles.push(isHighContrastMode ? '🔵' : '🟡️')
  tiles.push(isDarkMode ? '⚪️' : '⚪️')
  return tiles
}
