import { CogIcon } from '../icons/CogIcon'
import { HelpIcon } from '../icons/HelpIcon'
import { StatsIcon } from '../icons/StatsIcon'
import { KeydleLogo } from '../images/KeydleLogo'
import { LumiLogo } from '../images/LumiLogo'

type Props = {
  setIsInfoModalOpen: (value: boolean) => void
  setIsStatsModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
}

export const Navbar = ({
  setIsInfoModalOpen,
  setIsStatsModalOpen,
  setIsSettingsModalOpen,
}: Props) => {
  return (
    <div className="navbar">
      <div className="navbar-content pl-3 md:justify-center md:pl-0">
        <div className="pointer-events-none mt-4 mb-1 -ml-2 flex scale-90 flex-col sm:-ml-0 sm:scale-100 md:mt-8 md:scale-110 md:items-center">
          <div className="">
            <KeydleLogo />
          </div>
          <div className="mt-1">
            <LumiLogo />
          </div>
        </div>
        <div className="flex md:absolute md:right-0">
          <StatsIcon
            className="h-12 w-12 cursor-pointer transition hover:translate-y-0.5"
            onClick={() => setIsStatsModalOpen(true)}
          />
          <HelpIcon
            className="h-12 w-12 cursor-pointer transition hover:translate-y-0.5"
            onClick={() => setIsInfoModalOpen(true)}
          />
          <CogIcon
            className="h-12 w-12 cursor-pointer stroke-nav-icon transition hover:translate-y-0.5 dark:stroke-dark-nav-icon"
            onClick={() => setIsSettingsModalOpen(true)}
          />
        </div>
      </div>
    </div>
  )
}
