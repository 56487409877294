import { Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment } from 'react'

type Props = {
  isOpen: boolean
  message: string | Element
  variant?: 'success' | 'error' | 'info'
  topMost?: boolean
}

export const Alert = ({
  isOpen,
  message,
  variant = 'error',
  topMost = false,
}: Props) => {
  const classes = classNames(
    'fixed z-20 top-8 left-1/2 transform -translate-x-1/2 max-w-sm shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden p-2 sm:p-4',
    {
      'bg-alert-error-bg text-alert-error-text': variant === 'error',
      'bg-alert-success-bg text-alert-success-text': variant === 'success',
      'bg-alert-info-bg text-alert-info-text': variant === 'info',
    }
  )

  return (
    <Transition
      show={isOpen}
      as={Fragment}
      enter="ease-out duration-300 transition"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className={classes}>
        <div className="p-1">
          <p className="text-center text-base font-medium sm:text-xl">
            {message}
          </p>
        </div>
      </div>
    </Transition>
  )
}
