import { ENABLE_ARCHIVED_GAMES } from '../../constants/settings'
import { HARD_MODE_DESCRIPTION } from '../../constants/strings'
import { BaseModal } from './BaseModal'
import { SettingsToggle } from './SettingsToggle'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isHardMode: boolean
  hasPickedHardMode: boolean
  handleHardMode: Function
  isHighContrastMode: boolean
  handleHighContrastMode: Function
  isDarkMode: boolean
  handleDarkMode: Function
  handleOnNewMelody: () => void
}

export const SettingsModal = ({
  isOpen,
  handleClose,
  isHardMode,
  hasPickedHardMode,
  handleHardMode,
  isHighContrastMode,
  handleHighContrastMode,
  isDarkMode,
  handleDarkMode,
  handleOnNewMelody,
}: Props) => {
  return (
    <BaseModal title="Settings" isOpen={isOpen} handleClose={handleClose}>
      <div className="mt-2 flex flex-col">
        <SettingsToggle
          settingName="Hard Mode"
          flag={hasPickedHardMode}
          handleFlag={handleHardMode}
          description={HARD_MODE_DESCRIPTION}
        />
        <SettingsToggle
          settingName="Dark Theme"
          flag={isDarkMode}
          handleFlag={handleDarkMode}
        />
        {/* <SettingsToggle
          settingName="High Contrast"
          description={'For improved color vision.'}
          flag={isHighContrastMode}
          handleFlag={handleHighContrastMode}
        /> */}
        {ENABLE_ARCHIVED_GAMES && (
          <div>
            <button
              onClick={() => handleOnNewMelody()}
              className="mt-4 rounded-3xl border border-share-button-border px-10 py-1 font-light text-share-button-text dark:border-dark-share-button-border dark:text-dark-share-button-text"
            >
              New melody (for testing)
            </button>
          </div>
        )}
      </div>
    </BaseModal>
  )
}
