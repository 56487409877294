export const GAME_TITLE = 'Keydle'
export const SHARE_URL = 'keydle.playlumi.com'

export const WIN_MESSAGES = [
  'Great Job!',
  'Awesome!',
  'Well done!',
  'Impressive!',
]
export const GAME_COPIED_MESSAGE = 'Results copied to clipboard'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Not enough notes'
export const WORD_NOT_FOUND_MESSAGE = 'Word not found'
export const EASY_MODE_ALERT_MESSAGE = 'The first note is revealed'
export const HARD_MODE_ALERT_MESSAGE =
  'Hard Mode will be enabled for tomorrow’s melody'
export const HARD_MODE_DESCRIPTION =
  'In Hard Mode, the first note will not be revealed and the melody is played back faster.'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'For improved color vision'
export const CORRECT_WORD_MESSAGE = (solution: string) => (
  <span>
    <span className="alert-title block font-bold">Oops!</span>The correct melody
    was <br />
    {solution}
  </span>
)
export const CORRECT_WORD_MESSAGE_AFTER_RETURNING = (solution: string) => (
  <span>
    The correct melody was <br />
    {solution}
  </span>
)
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Must use ${guess} in position ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Guess must contain ${letter}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Delete'
export const STATISTICS_TITLE = 'Stats'
export const GUESS_DISTRIBUTION_TEXT = 'Guess distribution'
export const PLAY_BUTTON_TEXT = `Play today’s ${GAME_TITLE}`
export const REPLAY_BUTTON_TEXT = `Replay melody`
export const NEW_WORD_TEXT = `Next melody in`
export const SUCCESS_TEXT_PT_1 = `You got today’s melody in`
export const SUCCESS_TEXT_PT_2 = (numberOfGuesses: number) =>
  `${numberOfGuesses > 1 ? 'guesses' : 'guess'}!`
export const SHARE_TEXT = 'Share'
export const LUMI_PROMO_TEXT = `Unlock your musical potential with LUMI, the brightest way to learn piano.`
export const LUMI_EXTERNAL_URL_CTA = 'Learn more'
export const LUMI_EXTERNAL_URL_CTA_LINK = 'https://playlumi.com'
export const SHARE_FAILURE_TEXT =
  'Unable to share the results. This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.'
export const MIGRATE_BUTTON_TEXT = 'Transfer'
export const MIGRATE_DESCRIPTION_TEXT =
  'Click here to transfer your statistics to a new device.'
export const TOTAL_TRIES_TEXT = 'Played'
export const SUCCESS_RATE_TEXT = 'Win %'
export const CURRENT_STREAK_TEXT = 'Current Streak'
export const BEST_STREAK_TEXT = 'Best Streak'
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  "You are using an embedded browser and may experience problems sharing or saving your results. We encourage you rather to use your device's default browser."

export const DATEPICKER_TITLE = 'Choose a past date'
export const DATEPICKER_CHOOSE_TEXT = 'Choose'
export const DATEPICKER_TODAY_TEXT = 'today'
export const ARCHIVE_GAMEDATE_TEXT = 'Game date'

export const ABSENT_NOTE_HINT = 'Tip: grayed out keys aren’t in the melody.'
