import classnames from 'classnames'

import { PlayIcon } from '../icons/PlayIcon'

type Props = {
  isPlaying?: boolean
  isDisabled?: boolean
  isMainPlayButton?: boolean
  className?: string
  onPlay: () => void
}

export const RowPlayButton = ({
  onPlay,
  isDisabled,
  isPlaying,
  isMainPlayButton,
  className,
}: Props) => {
  const classes = classnames(
    'rounded-4xl ml-2 mr-4 flex h-10 w-10 content-center self-center align-middle short:ml-0.5 short:h-8 short:w-8',
    { 'cursor-pointer transition hover:scale-125': !isDisabled && !isPlaying },
    { 'short:mr-3': isPlaying && isMainPlayButton },
    { 'opacity-20': isDisabled },
    className
  )
  return (
    <div
      onClick={() => {
        if (isDisabled || isPlaying) return
        onPlay()
      }}
      className={classes}
    >
      <PlayIcon
        isPlaying={isPlaying}
        invertedColor={false}
        isMainPlayButton={isMainPlayButton}
        hasPlayedMelody={true}
      />
    </div>
  )
}
