import { useCallback, useEffect, useState } from 'react'

import {
  MAX_CHALLENGES,
  PLAYBACK_RATE,
  PLAYBACK_RATE_HARD,
} from '../../constants/settings'
import { getPlaybackRateMs, playMelody } from '../../lib/audio'
import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
import { EmptyRow } from './EmptyRow'
import { MelodyPlayButton } from './MelodyPlayButton'

type Props = {
  solution: number[]
  guesses: number[][]
  currentGuess: number[]
  isRevealing?: boolean
  currentRowClassName: string
  showStartingNote: boolean
  isDarkMode: boolean
  isHardMode: boolean
  isGameLost: boolean
  didFinishGameEarlier: boolean
  hasHeardMelody: boolean
  onMelodyPlaybackStarted: () => void
  onMelodyPlaybackEnded: () => void
}

export const Grid = ({
  solution,
  guesses,
  currentGuess,
  isRevealing,
  showStartingNote,
  isDarkMode,
  isHardMode,
  isGameLost,
  didFinishGameEarlier,
  currentRowClassName,
  hasHeardMelody,
  onMelodyPlaybackStarted,
  onMelodyPlaybackEnded,
}: Props) => {
  const empties =
    guesses.length < MAX_CHALLENGES - 1
      ? Array.from(Array(MAX_CHALLENGES - 1 - guesses.length))
      : []

  const [isPlaying, setIsPlaying] = useState(false)
  const [shouldShowSolution, setShouldShowSolution] = useState(isGameLost)

  useEffect(() => {
    if (isGameLost) {
      const delay = didFinishGameEarlier
        ? 0
        : (solution.length + 1) * getPlaybackRateMs(isHardMode)
      setTimeout(() => {
        setShouldShowSolution(true)
        onPlay()
      }, delay)
    }
  }, [isGameLost])

  const onPlay = useCallback(() => {
    setIsPlaying(true)
    onMelodyPlaybackStarted()
    playMelody(
      solution,
      isHardMode ? PLAYBACK_RATE_HARD : PLAYBACK_RATE,
      () => {
        setIsPlaying(false)
        onMelodyPlaybackEnded()
      }
    )
  }, [solution, isHardMode, onMelodyPlaybackStarted, onMelodyPlaybackEnded])

  return (
    <div className="m-auto max-w-full sm:max-w-2xl">
      <MelodyPlayButton
        onPlay={onPlay}
        isPlaying={isPlaying}
        isLumiMode={isDarkMode}
        isHardMode={isHardMode}
        showSolution={shouldShowSolution}
        solution={solution}
        hasPlayedMelody={hasHeardMelody}
      />
      {guesses.map((guess, i) => (
        <CompletedRow
          key={i}
          isDarkMode={isDarkMode}
          isHardMode={isHardMode}
          solution={solution}
          guess={guess}
          isRevealing={isRevealing && guesses.length - 1 === i}
        />
      ))}
      {guesses.length < MAX_CHALLENGES && (
        <CurrentRow
          isDarkMode={isDarkMode}
          isHardMode={isHardMode}
          guess={currentGuess}
          className={currentRowClassName}
          showStartingNote={showStartingNote}
        />
      )}
      {empties.map((_, i) => (
        <EmptyRow key={i} isDarkMode={isDarkMode} />
      ))}
    </div>
  )
}
