import classnames from 'classnames'
import { useMemo } from 'react'

import { RAINBOW_COLORS, setOpacity } from '../../constants/colors'
import { NOTES, NOTE_INDEXES } from '../../lib/audio'
import { CharStatus } from '../../lib/statuses'

type Props = {
  value?: string
  status?: CharStatus
  isRevealing?: boolean
  isCompleted?: boolean
  position?: number
  isGuide?: boolean
  isDarkMode: boolean
  isInsidePlayButton?: boolean
  animationDelay?: string
  className?: string
}

export const Cell = ({
  value,
  status,
  isRevealing,
  isCompleted,
  isGuide,
  isInsidePlayButton,
  animationDelay,
  className = '',
}: Props) => {
  const isFilled = value && !isCompleted
  const shouldReveal = isRevealing && isCompleted

  const classes = classnames(
    'flex items-center justify-center short:mx-0.5 mx-1 text-lg short:text-base md:text-2xl font-bold rounded grid-circle',
    {
      [className]: className,
      'short:text-1xl short:w-8 short:h-8 w-10 h-10 md:w-12 md:h-12':
        !isInsidePlayButton,
      'short:text-1xl short:w-8 short:h-8 w-10 h-10 md:w-11 md:h-11 text-white border-cell-correct dark:border-cell-correct -ml-1':
        isInsidePlayButton,
      'border-2': !isGuide && isInsidePlayButton,

      // empty
      'bg-cell-no-status-no-value dark:bg-dark-cell-no-status-no-value border-no-status-cell-border dark:border-dark-no-status-cell-border':
        !status && !isGuide && !isInsidePlayButton && !value,
      // no status but has text
      'bg-cell-no-status dark:bg-dark-cell-no-status border border-no-status-cell-border text-cell-no-status-text dark:text-dark-cell-no-status-text':
        value && !status && !isInsidePlayButton,
      // absent
      'absent bg-cell-absent dark:bg-dark-cell-absent text-cell-absent-text dark:text-dark-cell-absent-text':
        status === 'absent',
      // correct
      'correct bg-cell-correct dark:bg-dark-cell-correct text-white':
        status === 'correct',
      // present
      'present bg-cell-present dark:bg-cell-present text-white':
        status === 'present',
      // animation
      'cell-fill-animation': isFilled && !isInsidePlayButton,
      'cell-reveal': shouldReveal && !isInsidePlayButton,
      // first note guide
      'bg-no-status border border-no-status-cell-border dark:border-dark-no-status-cell-border dark:text-white opacity-50':
        isGuide && !isInsidePlayButton,
    }
  )

  const letterClasses = classnames('letter-container')

  return (
    <div
      className={classes}
      style={{
        animationDelay,
      }}
    >
      <div className={letterClasses} style={{ animationDelay }}>
        {value}
      </div>
    </div>
  )
}
