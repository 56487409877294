import classnames from 'classnames'
import { useCallback, useState } from 'react'

import { PLAYBACK_RATE, PLAYBACK_RATE_HARD } from '../../constants/settings'
import { NOTES, getPlaybackRateMs, playMelody } from '../../lib/audio'
import { getGuessStatuses } from '../../lib/statuses'
import { Cell } from './Cell'
import { RowContainer } from './RowContainer'
import { RowPlayButton } from './RowPlayButton'

type Props = {
  solution: number[]
  guess: number[]
  isRevealing?: boolean
  isDarkMode: boolean
  isHardMode: boolean
}

export const CompletedRow = ({
  solution,
  guess,
  isRevealing,
  isDarkMode,
  isHardMode,
}: Props) => {
  const statuses = getGuessStatuses(solution, guess)
  const [isPlaying, setIsPlaying] = useState(false)

  const playbackRate = getPlaybackRateMs(isHardMode)

  const onPlay = useCallback(() => {
    if (isPlaying) return
    setIsPlaying(true)
    playMelody(guess, isHardMode ? PLAYBACK_RATE_HARD : PLAYBACK_RATE, () => {
      setIsPlaying(false)
    })
  }, [guess, isPlaying, isHardMode])

  return (
    <div className="mb-1 flex justify-center">
      <RowContainer>
        <RowPlayButton onPlay={onPlay} isPlaying={isPlaying} />
        {guess.map((noteIndex, i) => (
          <Cell
            key={i}
            value={NOTES[noteIndex]}
            status={statuses[i]}
            isRevealing={isRevealing}
            isCompleted
            isDarkMode={isDarkMode}
            animationDelay={`${i * playbackRate}ms`}
            className={classnames({ 'animation-pop': isPlaying })}
          />
        ))}
      </RowContainer>
    </div>
  )
}
