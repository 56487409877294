import { enUS } from 'date-fns/locale'

export const MAX_CHALLENGES = 6
export const ALERT_TIME_MS = 2000
export const LONG_ALERT_TIME_MS = 10000
export const REVEAL_TIME_MS = 250
export const WELCOME_INFO_MODAL_MS = 350
export const DISCOURAGE_INAPP_BROWSERS = false
export const ENABLE_MIGRATE_STATS = true
export const ENABLE_ARCHIVED_GAMES = false
export const DATE_LOCALE = enUS
export const PLAYBACK_RATE = 0.6
export const PLAYBACK_RATE_HARD = 0.9
