import {
  differenceInMilliseconds,
  intervalToDuration,
  startOfToday,
  startOfYesterday,
} from 'date-fns'

export const getToday = () => startOfToday()
export const getYesterday = () => startOfYesterday()

export const isAprilFoolsDay = (date: Date) => {
  return date.getMonth() === 3 && date.getDate() === 1
}

export const getTimeTaken = (startTime: Date, completeTime: Date) => {
  const duration = intervalToDuration({
    start: startTime,
    end: completeTime,
  })

  const hours = duration.hours ?? 0
  const mins = duration.minutes ?? 0
  const seconds = duration.seconds ?? 0
  const timeTaken = `${hours > 0 ? hours + 'h' : ''}${
    mins > 0 ? mins + 'm' : ''
  }${seconds > 0 ? seconds + 's' : ''}`

  if (hours == 0 && mins == 0 && seconds < 10) {
    const milliseconds = differenceInMilliseconds(completeTime, startTime)
    let toSeconds = Number((milliseconds / 1000).toFixed(1))
    if (seconds < 1) {
      toSeconds = Number((milliseconds / 1000).toFixed(3))
    }
    return `${toSeconds}s`
  }

  return timeTaken
}
