import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  ABSENT_COLOR,
  CORRECT_COLOR,
  DARK_ABSENT_COLOR,
  DARK_KEYBOARD_NOTE_AVAILABLE_COLOR,
  DARK_KEY_ACTIVE_COLOR,
  DARK_KEY_TEXT_ABSENT_COLOR,
  DARK_KEY_TEXT_DEFAULT_COLOR,
  DEFAULT_KEYBOARD_NOTE_AVAILABLE_COLOR,
  KEY_ACTIVE_COLOR,
  KEY_TEXT_ABSENT_COLOR,
  KEY_TEXT_CORRECT_COLOR,
  KEY_TEXT_DEFAULT_COLOR,
  KEY_TEXT_PRESENT_COLOR,
  PRESENT_COLOR,
} from '../../constants/colors'
import { NOTES, isWhiteNote } from '../../lib/audio'
import { localeAwareUpperCase } from '../../lib/melodies'
import { getStatuses } from '../../lib/statuses'

type Props = {
  onNewNote: (value: number, status: string) => void
  onDelete: () => void
  onEnter: () => void
  solution: number[]
  guesses: number[][]
  showStartingNote: boolean
  isDisabled?: boolean
  isDarkMode: boolean
}

export const Keyboard = ({
  onNewNote,
  onDelete,
  onEnter,
  solution,
  guesses,
  showStartingNote,
  isDarkMode,
  isDisabled = false,
}: Props) => {
  const charStatuses = getStatuses(solution, guesses)

  const [activeKeysList, setActiveKeysList] = useState(Array(12).fill(false))

  const onNoteOn = useCallback(
    (value: number) => {
      const newActiveKeys = [...activeKeysList]
      newActiveKeys[value] = true
      setActiveKeysList(newActiveKeys)
    },
    [activeKeysList]
  )

  const onNoteOff = useCallback(
    (value: number) => {
      const newActiveKeys = [...activeKeysList]
      newActiveKeys[value] = false
      setActiveKeysList(newActiveKeys)
    },
    [activeKeysList]
  )

  const onPointerDown = useCallback(
    (value: number) => {
      if (!isDisabled) {
        onNoteOn(value)
        onNewNote(value, charStatuses[NOTES[value]])
      }
    },
    [onNewNote, onNoteOn, isDisabled]
  )

  const onPointerUp = useCallback(
    (value: number) => {
      onNoteOff(value)
    },
    [onNoteOff]
  )

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (
        isDisabled ||
        e.shiftKey ||
        e.altKey ||
        e.ctrlKey ||
        e.metaKey ||
        e.repeat
      )
        return
      e.preventDefault()
      const key = localeAwareUpperCase(e.key)
      if (key.length === 1 && key >= 'A' && key <= 'Z') {
        const keysToNoteIndex: any = {
          Z: 0,
          S: 1,
          X: 2,
          D: 3,
          C: 4,
          V: 5,
          G: 6,
          B: 7,
          H: 8,
          N: 9,
          J: 10,
          M: 11,
        }
        if (keysToNoteIndex[key] !== undefined) {
          onNewNote(
            keysToNoteIndex[key],
            charStatuses[NOTES[keysToNoteIndex[key]]]
          )
        }
      }
    }
    window.addEventListener('keydown', listener)
    return () => {
      window.removeEventListener('keydown', listener)
    }
  }, [isDisabled, onEnter, onDelete, onNewNote])

  const keyBackgroundColors = useMemo(() => {
    const colors: string[] = Array(12).fill(
      isDarkMode
        ? DARK_KEYBOARD_NOTE_AVAILABLE_COLOR
        : DEFAULT_KEYBOARD_NOTE_AVAILABLE_COLOR
    )

    for (let i = 0; i < 12; i++) {
      if (activeKeysList[i]) {
        colors[i] = isDarkMode ? DARK_KEY_ACTIVE_COLOR : KEY_ACTIVE_COLOR
      } else if (charStatuses[NOTES[i]] === 'correct') {
        colors[i] = CORRECT_COLOR
      } else if (charStatuses[NOTES[i]] === 'present') {
        colors[i] = PRESENT_COLOR
      } else if (charStatuses[NOTES[i]] === 'absent') {
        colors[i] = isDarkMode ? DARK_ABSENT_COLOR : ABSENT_COLOR
      }
    }

    return colors
  }, [activeKeysList, charStatuses, isDarkMode])

  const keyTextColors = useMemo(() => {
    const colors: string[] = Array(12).fill(
      isDarkMode ? DARK_KEY_TEXT_DEFAULT_COLOR : KEY_TEXT_DEFAULT_COLOR
    )

    for (let i = 0; i < 12; i++) {
      if (charStatuses[NOTES[i]] === 'correct') {
        colors[i] = KEY_TEXT_CORRECT_COLOR
      } else if (charStatuses[NOTES[i]] === 'present') {
        colors[i] = KEY_TEXT_PRESENT_COLOR
      } else if (charStatuses[NOTES[i]] === 'absent') {
        colors[i] = isDarkMode
          ? DARK_KEY_TEXT_ABSENT_COLOR
          : KEY_TEXT_ABSENT_COLOR
      }
    }

    return colors
  }, [charStatuses, isDarkMode])

  const keyTextXPositions = [
    38, 70, 115, 162, 196, 275, 312, 356, 396, 436, 482, 518,
  ]

  const keyClassName = 'transition'

  return (
    <div>
      <div
        className={classNames(
          'keyboard touch-highlight-off flex justify-center',
          {
            'cursor-pointer': !isDisabled,
          }
        )}
      >
        <svg
          className="max-h-40 max-w-xl lg:max-h-full"
          width="100%"
          height="100%"
          viewBox="0 0 552 180"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            className={keyClassName}
            onPointerDown={() => onPointerDown(0)}
            onPointerUp={() => onPointerUp(0)}
            onPointerLeave={() => onPointerUp(0)}
            onPointerCancel={() => onPointerUp(0)}
            fill={keyBackgroundColors[0]}
          >
            <path d="M70 180H24C10.7452 180 0 169.255 0 156V2C0 0.895431 0.895431 0 2 0H42C43.1046 0 44 0.89543 44 2V96C44 104.837 51.1634 112 60 112H70C71.1046 112 72 112.895 72 114V178C72 179.105 71.1046 180 70 180Z" />
          </g>
          <g className={keyClassName} fill={keyBackgroundColors[1]}>
            <path d="M76 104H64C57.3726 104 52 98.6274 52 92V2C52 0.895432 52.8954 0 54 0H86C87.1046 0 88 0.895432 88 2V92C88 98.6274 82.6274 104 76 104Z" />
          </g>
          <g
            className={keyClassName}
            onPointerDown={() => onPointerDown(2)}
            onPointerUp={() => onPointerUp(2)}
            onPointerLeave={() => onPointerUp(2)}
            onPointerCancel={() => onPointerUp(2)}
            fill={keyBackgroundColors[2]}
          >
            <path d="M82 180H150C151.105 180 152 179.105 152 178V113.778C152 112.796 151.204 112 150.222 112C142.368 112 136 105.632 136 97.7778V2C136 0.895431 135.105 0 134 0H98C96.8954 0 96 0.89543 96 2V97.7778C96 105.632 89.6325 112 81.7778 112C80.7959 112 80 112.796 80 113.778V178C80 179.105 80.8954 180 82 180Z" />
          </g>
          <g className={keyClassName} fill={keyBackgroundColors[3]}>
            <path d="M168 104H156C149.373 104 144 98.6274 144 92V2C144 0.895432 144.895 0 146 0H178C179.105 0 180 0.895432 180 2V92C180 98.6274 174.627 104 168 104Z" />
          </g>
          <g
            className={keyClassName}
            onPointerDown={() => onPointerDown(4)}
            onPointerUp={() => onPointerUp(4)}
            onPointerLeave={() => onPointerUp(4)}
            onPointerCancel={() => onPointerUp(4)}
            fill={keyBackgroundColors[4]}
          >
            <path d="M162 180H230C231.105 180 232 179.105 232 178V2C232 0.895431 231.105 0 230 0H190C188.895 0 188 0.89543 188 2V96C188 104.837 180.837 112 172 112H162C160.895 112 160 112.895 160 114V178C160 179.105 160.895 180 162 180Z" />
          </g>
          <g
            className={keyClassName}
            onPointerDown={() => onPointerDown(5)}
            onPointerUp={() => onPointerUp(5)}
            onPointerLeave={() => onPointerUp(5)}
            onPointerCancel={() => onPointerUp(5)}
            fill={keyBackgroundColors[5]}
          >
            <path d="M310 180H242C240.895 180 240 179.105 240 178V2C240 0.895431 240.895 0 242 0H282C283.105 0 284 0.89543 284 2V96C284 104.837 291.163 112 300 112H310C311.105 112 312 112.895 312 114V178C312 179.105 311.105 180 310 180Z" />
          </g>
          <g className={keyClassName} fill={keyBackgroundColors[6]}>
            <path d="M316 104H304C297.373 104 292 98.6274 292 92V2C292 0.895432 292.895 0 294 0H326C327.105 0 328 0.895432 328 2V92C328 98.6274 322.627 104 316 104Z" />
          </g>
          <g
            className={keyClassName}
            onPointerDown={() => onPointerDown(7)}
            onPointerUp={() => onPointerUp(7)}
            onPointerLeave={() => onPointerUp(7)}
            onPointerCancel={() => onPointerUp(7)}
            fill={keyBackgroundColors[7]}
          >
            <path d="M322 180H390C391.105 180 392 179.105 392 178V114C392 112.895 391.105 112 390 112H386C377.163 112 370 104.837 370 96V2C370 0.895431 369.105 0 368 0H338C336.895 0 336 0.89543 336 2V97.7778C336 105.632 329.632 112 321.778 112C320.796 112 320 112.796 320 113.778V178C320 179.105 320.895 180 322 180Z" />
          </g>
          <g className={keyClassName} fill={keyBackgroundColors[8]}>
            <path d="M402 104H390C383.373 104 378 98.6274 378 92V2C378 0.895432 378.895 0 380 0H412C413.105 0 414 0.895432 414 2V92C414 98.6274 408.627 104 402 104Z" />
          </g>
          <g
            className={keyClassName}
            onPointerDown={() => onPointerDown(9)}
            onPointerUp={() => onPointerUp(9)}
            onPointerLeave={() => onPointerUp(9)}
            onPointerCancel={() => onPointerUp(9)}
            fill={keyBackgroundColors[9]}
          >
            <path d="M470 180H402C400.895 180 400 179.105 400 178V114C400 112.895 400.895 112 402 112H406C414.837 112 422 104.837 422 96V2C422 0.895431 422.895 0 424 0H454C455.105 0 456 0.89543 456 2V97.7778C456 105.632 462.368 112 470.222 112C471.204 112 472 112.796 472 113.778V178C472 179.105 471.105 180 470 180Z" />
          </g>
          <g className={keyClassName} fill={keyBackgroundColors[10]}>
            <path d="M492 104H472C467.582 104 464 100.418 464 96V2C464 0.895432 464.895 0 466 0H498C499.105 0 500 0.895432 500 2V96C500 100.418 496.418 104 492 104Z" />
          </g>
          <g
            className={keyClassName}
            onPointerDown={() => onPointerDown(11)}
            onPointerUp={() => onPointerUp(11)}
            onPointerLeave={() => onPointerUp(11)}
            onPointerCancel={() => onPointerUp(11)}
            fill={keyBackgroundColors[11]}
          >
            <path d="M482 180H532C543.046 180 552 171.046 552 160V2C552 0.895431 551.105 0 550 0H510C508.895 0 508 0.89543 508 2V96C508 104.837 500.837 112 492 112H482C480.895 112 480 112.895 480 114V178C480 179.105 480.895 180 482 180Z" />
          </g>

          {/* Black note hit areas  */}
          <rect
            x="30"
            y="-20"
            width="75"
            height="125"
            rx="30"
            fill="transparent"
            onPointerDown={() => onPointerDown(1)}
            onPointerUp={() => onPointerUp(1)}
            onPointerLeave={() => onPointerUp(1)}
            onPointerCancel={() => onPointerUp(1)}
          ></rect>
          <rect
            x="125"
            y="-20"
            width="75"
            height="125"
            rx="30"
            fill="transparent"
            onPointerDown={() => onPointerDown(3)}
            onPointerUp={() => onPointerUp(3)}
            onPointerLeave={() => onPointerUp(3)}
            onPointerCancel={() => onPointerUp(3)}
          ></rect>
          <rect
            x="272"
            y="-20"
            width="75"
            height="125"
            rx="30"
            fill="transparent"
            onPointerDown={() => onPointerDown(6)}
            onPointerUp={() => onPointerUp(6)}
            onPointerLeave={() => onPointerUp(6)}
            onPointerCancel={() => onPointerUp(6)}
          ></rect>
          <rect
            x="360"
            y="-20"
            width="75"
            height="125"
            rx="30"
            fill="transparent"
            onPointerDown={() => onPointerDown(8)}
            onPointerUp={() => onPointerUp(8)}
            onPointerLeave={() => onPointerUp(8)}
            onPointerCancel={() => onPointerUp(8)}
          ></rect>
          <rect
            x="445"
            y="-20"
            width="75"
            height="125"
            rx="30"
            fill="transparent"
            onPointerDown={() => onPointerDown(10)}
            onPointerUp={() => onPointerUp(10)}
            onPointerLeave={() => onPointerUp(10)}
            onPointerCancel={() => onPointerUp(10)}
          ></rect>

          {showStartingNote && (
            <g className="pointer-events-none">
              <circle
                className={classNames({
                  'animate-pulse':
                    charStatuses[NOTES[solution[0]]] !== 'correct',
                })}
                cx={keyTextXPositions[solution[0]]}
                cy={isWhiteNote(solution[0]) ? 154 : 82}
                r={0}
                fill={CORRECT_COLOR}
              />
            </g>
          )}
          <g className="pointer-events-none">
            <path
              d="M45.368 161.6L43 158.624C40.984 160.032 39.672 160.512 38.104 160.512C34.232 160.512 31.096 157.376 31.096 153.344C31.096 149.312 34.232 146.176 38.104 146.176C39.736 146.176 40.92 146.592 43.032 148.064L45.368 145.152C43.16 143.36 40.792 142.432 38.104 142.432C32.056 142.432 27 147.264 27 153.344C27 159.392 31.992 164.256 38.104 164.256C40.568 164.256 42.648 163.648 45.368 161.6Z"
              fill={keyTextColors[0]}
              className="key-highlight"
            />
            <g>
              <path
                d="M77.6201 83.342C77.6201 78.402 73.6681 74.684 68.7281 74.684H62.8521V92H68.7281C73.6681 92 77.6201 88.282 77.6201 83.342ZM74.2921 83.342C74.2921 86.592 71.6661 89.062 68.4421 89.062H66.1021V77.622H68.4421C71.6661 77.622 74.2921 80.092 74.2921 83.342Z"
                fill={keyTextColors[1]}
              />
              <path
                d="M78.9998 79.4863L80.1336 79.2666C83.0428 78.7129 84.7743 77.166 84.7743 74.9336V74.916C84.7743 73.5449 83.8426 72.4902 82.5418 72.4902C81.6454 72.4902 81.0038 72.9736 80.7313 73.457H80.6873V69.3174L78.9998 69.6426V79.4863ZM80.6873 77.667V75.54C80.6873 74.5557 81.2235 74.0723 81.8739 74.0723C82.4627 74.0723 82.9198 74.5117 82.9198 75.2148V75.2324C82.9198 76.375 81.9354 77.2979 80.6873 77.667Z"
                fill={keyTextColors[1]}
              />
            </g>
            <path
              d="M125.176 153.344C125.176 147.264 120.312 142.688 114.232 142.688H107V164H114.232C120.312 164 125.176 159.424 125.176 153.344ZM121.08 153.344C121.08 157.344 117.848 160.384 113.88 160.384H111V146.304H113.88C117.848 146.304 121.08 149.344 121.08 153.344Z"
              fill={keyTextColors[2]}
            />
            <g>
              <path
                d="M166.318 92V89.062H158.778V84.72H166.032V81.782H158.778V77.622H166.318V74.684H155.528V92H166.318Z"
                fill={keyTextColors[3]}
              />
              <path
                d="M169.324 79.4863L170.458 79.2666C173.367 78.7129 175.098 77.166 175.098 74.9336V74.916C175.098 73.5449 174.167 72.4902 172.866 72.4902C171.97 72.4902 171.328 72.9736 171.056 73.457H171.012V69.3174L169.324 69.6426V79.4863ZM171.012 77.667V75.54C171.012 74.5557 171.548 74.0723 172.198 74.0723C172.787 74.0723 173.244 74.5117 173.244 75.2148V75.2324C173.244 76.375 172.26 77.2979 171.012 77.667Z"
                fill={keyTextColors[3]}
              />
            </g>
            <path
              d="M202.28 164V160.384H193V155.04H201.928V151.424H193V146.304H202.28V142.688H189V164H202.28Z"
              fill={keyTextColors[4]}
            />
            <path
              d="M282.28 146.304V142.688H269V164H273V155.04H281.928V151.424H273V146.304H282.28Z"
              fill={keyTextColors[5]}
            />
            <g>
              <path
                d="M318.934 83.1026V81.4906H309.782V84.4546H315.554C315.268 86.8986 313.162 88.8486 310.354 88.8486C307.156 88.8486 304.66 86.2746 304.66 83.0246C304.66 79.7746 307.182 77.2006 310.354 77.2006C312.07 77.2006 313.5 77.9286 315.164 79.7226L317.53 77.7726C315.476 75.2506 313.058 74.1586 310.354 74.1586C305.362 74.1586 301.332 78.1366 301.332 83.0246C301.332 87.9126 305.31 91.8906 310.354 91.8906C315.502 91.8906 318.934 87.8606 318.934 83.1026Z"
                fill={keyTextColors[6]}
              />
              <path
                d="M319.351 79.1689L320.484 78.9492C323.393 78.3955 325.125 76.8486 325.125 74.6162V74.5986C325.125 73.2275 324.193 72.1729 322.893 72.1729C321.996 72.1729 321.354 72.6562 321.082 73.1396H321.038V69L319.351 69.3252V79.1689ZM321.038 77.3496V75.2227C321.038 74.2383 321.574 73.7549 322.225 73.7549C322.813 73.7549 323.27 74.1943 323.27 74.8975V74.915C323.27 76.0576 322.286 76.9805 321.038 77.3496Z"
                fill={keyTextColors[6]}
              />
            </g>
            <path
              d="M366.664 153.44V151.456H355.4V155.104H362.504C362.152 158.112 359.56 160.512 356.104 160.512C352.168 160.512 349.096 157.344 349.096 153.344C349.096 149.344 352.2 146.176 356.104 146.176C358.216 146.176 359.976 147.072 362.024 149.28L364.936 146.88C362.408 143.776 359.432 142.432 356.104 142.432C349.96 142.432 345 147.328 345 153.344C345 159.36 349.896 164.256 356.104 164.256C362.44 164.256 366.664 159.296 366.664 153.44Z"
              fill={keyTextColors[7]}
            />
            <g>
              <path
                d="M404.379 92L396.085 74.112H395.305L386.959 92H390.443L392.497 87.476H398.815L400.895 92H404.379ZM397.463 84.538H393.849L395.669 80.612L397.463 84.538Z"
                fill={keyTextColors[8]}
              />
              <path
                d="M404.111 79.4863L405.245 79.2666C408.154 78.7129 409.886 77.166 409.886 74.9336V74.916C409.886 73.5449 408.954 72.4902 407.653 72.4902C406.757 72.4902 406.115 72.9736 405.843 73.457H405.799V69.3174L404.111 69.6426V79.4863ZM405.799 77.667V75.54C405.799 74.5557 406.335 74.0723 406.985 74.0723C407.574 74.0723 408.031 74.5117 408.031 75.2148V75.2324C408.031 76.375 407.047 77.2979 405.799 77.667Z"
                fill={keyTextColors[8]}
              />
            </g>
            <path
              d="M446.44 164L436.232 141.984H435.272L425 164H429.288L431.816 158.432H439.592L442.152 164H446.44ZM437.928 154.816H433.48L435.72 149.984L437.928 154.816Z"
              fill={keyTextColors[9]}
            />
            <g>
              <path
                d="M488.922 86.8C488.922 85.11 488.116 83.784 486.452 82.926C487.752 82.302 488.48 80.976 488.48 79.598C488.48 77.804 487.31 74.684 483.046 74.684H476V92H483.046C487.57 92 488.922 88.906 488.922 86.8ZM485.178 79.624C485.178 81.028 484.19 81.6 482.318 81.6H479.25V77.622H482.318C484.19 77.622 485.178 78.22 485.178 79.624ZM485.62 86.8C485.62 87.788 485.022 89.062 482.786 89.062H479.25V84.564H482.786C485.022 84.564 485.62 85.812 485.62 86.8Z"
                fill={keyTextColors[10]}
              />
              <path
                d="M489.802 79.4863L490.936 79.2666C493.845 78.7129 495.577 77.166 495.577 74.9336V74.916C495.577 73.5449 494.645 72.4902 493.344 72.4902C492.448 72.4902 491.806 72.9736 491.534 73.457H491.49V69.3174L489.802 69.6426V79.4863ZM491.49 77.667V75.54C491.49 74.5557 492.026 74.0723 492.676 74.0723C493.265 74.0723 493.722 74.5117 493.722 75.2148V75.2324C493.722 76.375 492.738 77.2979 491.49 77.667Z"
                fill={keyTextColors[10]}
              />
            </g>
            <path
              d="M523.904 157.6C523.904 155.52 522.912 153.888 520.864 152.832C522.464 152.064 523.36 150.432 523.36 148.736C523.36 146.528 521.92 142.688 516.672 142.688H508V164H516.672C522.24 164 523.904 160.192 523.904 157.6ZM519.296 148.768C519.296 150.496 518.08 151.2 515.776 151.2H512V146.304H515.776C518.08 146.304 519.296 147.04 519.296 148.768ZM519.84 157.6C519.84 158.816 519.104 160.384 516.352 160.384H512V154.848H516.352C519.104 154.848 519.84 156.384 519.84 157.6Z"
              fill={keyTextColors[11]}
            />
          </g>
        </svg>
      </div>
    </div>
  )
}
