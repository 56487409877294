import classnames from 'classnames'

type Props = {
  settingName: string
  flag: boolean
  handleFlag: Function
  description?: string
}

export const SettingsToggle = ({
  settingName,
  flag,
  handleFlag,
  description,
}: Props) => {
  const toggleHolder = classnames(
    'border w-14 h-8 flex shrink-0 items-center rounded-full p-1 duration-300 ease-in-out cursor-pointer',
    {
      'border-white bg-toggle-button-on dark:bg-dark-toggle-bg-on': flag,
      'border-white bg-toggle-button-off dark:bg-dark-toggle-bg-off dark:border-dark-toggle-bg-off':
        !flag,
    }
  )
  const toggleButton = classnames(
    'toggle-button w-7 h-6 rounded-full shadow-md bg-white transform duration-300 ease-in-out cursor-pointer text-xxs flex justify-center items-center',
    {
      'translate-x-5 text-toggle-button-on dark:text-black': flag,
      'text-toggle-button-off dark:bg-dark-toggle-button-off dark:text-dark-toggle-bg-off':
        !flag,
    }
  )

  return (
    <>
      <div className="flex justify-between gap-4 py-3">
        <div className="mt-2 text-left">
          <h4 className="leading-none">{settingName}</h4>
          {description && <p className="mt-1 text-sm">{description}</p>}
        </div>
        <div className={toggleHolder} onClick={() => handleFlag(!flag)}>
          <div className={toggleButton}>{flag ? 'ON' : 'OFF'}</div>
        </div>
      </div>
    </>
  )
}
