import classnames from 'classnames'
import { useState } from 'react'

import { PLAYBACK_RATE } from '../../constants/settings'
import { getPlaybackRateMs, playMelody } from '../../lib/audio'
import { Cell } from '../grid/Cell'
import { RowContainer } from '../grid/RowContainer'
import { RowPlayButton } from '../grid/RowPlayButton'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  isDarkMode: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, isDarkMode, handleClose }: Props) => {
  const [isPlaying1, setIsPlaying1] = useState(false)
  const [isPlaying2, setIsPlaying2] = useState(false)
  const [isPlaying3, setIsPlaying3] = useState(false)

  const melodyPlaybackRate = PLAYBACK_RATE
  const playbackRate = getPlaybackRateMs(false)

  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <div className="info-modal flex grow flex-col justify-center">
        <p className="m-auto max-w-sm py-2 px-4 text-sm">
          Listen to today’s melody, then guess the notes. After each guess, the
          color of the notes will change to show how close your guess was to the
          melody.
        </p>
        <div className="m-auto max-w-full sm:max-w-2xl">
          <div className="mb-1 mt-4 flex justify-center">
            <RowContainer>
              <RowPlayButton
                onPlay={() => {
                  setIsPlaying1(true)
                  playMelody([0, 2, 3, 5, 7, 8], melodyPlaybackRate, () => {
                    setIsPlaying1(false)
                  })
                }}
                isPlaying={isPlaying1}
              />
              <Cell
                animationDelay={isPlaying1 ? `${0 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying1 })}
                isCompleted={true}
                value="C"
                status="correct"
                isDarkMode={isDarkMode}
              />
              <Cell
                animationDelay={isPlaying1 ? `${1 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying1 })}
                isDarkMode={isDarkMode}
                value="D"
                isCompleted={true}
                status={'absent'}
              />
              <Cell
                animationDelay={isPlaying1 ? `${2 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying1 })}
                isDarkMode={isDarkMode}
                value="E♭"
                isCompleted={true}
                status={'absent'}
              />
              <Cell
                animationDelay={isPlaying1 ? `${3 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying1 })}
                isDarkMode={isDarkMode}
                value="F"
                isCompleted={true}
                status={'absent'}
              />
              <Cell
                animationDelay={isPlaying1 ? `${4 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying1 })}
                isDarkMode={isDarkMode}
                value="G"
                isCompleted={true}
                status={'absent'}
              />
              <Cell
                animationDelay={isPlaying1 ? `${5 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying1 })}
                isDarkMode={isDarkMode}
                value="A♭"
                isCompleted={true}
                status={'absent'}
              />
            </RowContainer>
          </div>
          <p className="text-sm">The C note is in the correct spot.</p>

          <div className="mb-1 mt-4 flex max-w-md justify-center">
            <RowContainer>
              <RowPlayButton
                onPlay={() => {
                  setIsPlaying2(true)
                  playMelody([0, 2, 4, 5, 7, 9], melodyPlaybackRate, () => {
                    setIsPlaying2(false)
                  })
                }}
                isPlaying={isPlaying2}
              />
              <Cell
                animationDelay={isPlaying2 ? `${0 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying2 })}
                isDarkMode={isDarkMode}
                value="C"
                isCompleted={true}
                status={'absent'}
              />
              <Cell
                animationDelay={isPlaying2 ? `${1 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying2 })}
                isDarkMode={isDarkMode}
                value="D"
                isCompleted={true}
                status={'absent'}
              />
              <Cell
                animationDelay={isPlaying2 ? `${2 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying2 })}
                isDarkMode={isDarkMode}
                isCompleted={true}
                value="E"
                status="present"
              />
              <Cell
                animationDelay={isPlaying2 ? `${3 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying2 })}
                isDarkMode={isDarkMode}
                value="F"
                isCompleted={true}
                status={'absent'}
              />
              <Cell
                animationDelay={isPlaying2 ? `${4 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying2 })}
                isDarkMode={isDarkMode}
                value="G"
                isCompleted={true}
                status={'absent'}
              />
              <Cell
                animationDelay={isPlaying2 ? `${5 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying2 })}
                isDarkMode={isDarkMode}
                value="A"
                isCompleted={true}
                status={'absent'}
              />
            </RowContainer>
          </div>
          <p className="text-sm">
            The note E is in the melody but in the wrong spot.
          </p>

          <div className="mb-1 mt-4 flex max-w-md justify-center">
            <RowContainer>
              <RowPlayButton
                onPlay={() => {
                  setIsPlaying3(true)
                  playMelody([5, 7, 9, 10, 3, 2], melodyPlaybackRate, () => {
                    setIsPlaying3(false)
                  })
                }}
                isPlaying={isPlaying3}
                className="animate-less-gentle-ping"
              />
              <Cell
                animationDelay={isPlaying3 ? `${0 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying3 })}
                isDarkMode={isDarkMode}
                value="F"
                isCompleted={true}
                status={'absent'}
              />
              <Cell
                animationDelay={isPlaying3 ? `${1 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying3 })}
                isDarkMode={isDarkMode}
                value="G"
                isCompleted={true}
                status={'absent'}
              />
              <Cell
                animationDelay={isPlaying3 ? `${2 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying3 })}
                isDarkMode={isDarkMode}
                value="A"
                isCompleted={true}
                status={'absent'}
              />
              <Cell
                animationDelay={isPlaying3 ? `${3 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying3 })}
                isDarkMode={isDarkMode}
                isCompleted={true}
                value="B♭"
                status={'absent'}
              />
              <Cell
                animationDelay={isPlaying3 ? `${4 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying3 })}
                isDarkMode={isDarkMode}
                value="E♭"
                isCompleted={true}
                status={'absent'}
              />
              <Cell
                animationDelay={isPlaying3 ? `${5 * playbackRate}ms` : '0ms'}
                className={classnames({ 'animation-pop': isPlaying3 })}
                isDarkMode={isDarkMode}
                value="D"
                isCompleted={true}
                status={'absent'}
              />
            </RowContainer>
          </div>
          <p className="text-sm">
            Use the play button to hear the previous guesses
          </p>

          <p className="mt-6 text-sm">
            <a href="https://playlumi.com/" className="underline">
              Start learning piano with LUMI
            </a>
          </p>
        </div>
      </div>
    </BaseModal>
  )
}
