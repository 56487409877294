import classnames from 'classnames'

type Props = {}

export const CloseIcon = (props: Props) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        className="stroke-close-icon dark:stroke-dark-close-icon"
        strokeWidth={2}
      >
        <path d="M16 16L32 32" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M32 16L16 32" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}
