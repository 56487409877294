import classnames from 'classnames'

type Props = {
  invertedColor?: boolean
  isPlaying?: boolean
  isMainPlayButton?: boolean
  hasPlayedMelody?: boolean
}

export const PlayIcon = ({
  invertedColor,
  isPlaying,
  isMainPlayButton,
  hasPlayedMelody,
}: Props) => {
  if (isPlaying) {
    return (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classnames(
          'play-icon-animating h-full w-full fill-black dark:fill-white',
          {
            'fill-play-icon-has-played dark:fill-dark-play-icon-has-played':
              isMainPlayButton,
          }
        )}
      >
        <rect
          className="play-icon-bar-left"
          x="30"
          y="12"
          width="4"
          height="4"
          rx="2"
        />
        <rect
          className="play-icon-bar-middle"
          x="23"
          y="12"
          width="4"
          height="4"
          rx="2"
        />
        <rect
          className="play-icon-bar-right"
          x="16"
          y="12"
          width="4"
          height="4"
          rx="2"
        />
      </svg>
    )
  }

  return (
    <svg
      width="48"
      height="40"
      viewBox="0 0 48 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classnames('h-full w-full', {
        'stroke-black dark:stroke-white': !invertedColor,
        'stroke-white': invertedColor,
        'stroke-play-icon-default dark:stroke-dark-play-icon-default':
          isMainPlayButton && !hasPlayedMelody,
        'stroke-play-icon-has-played dark:stroke-dark-play-icon-has-played':
          isMainPlayButton && hasPlayedMelody,
      })}
    >
      {/* <g filter="url(#filter0_f_129_3309)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.0372 18.7382C30.9557 19.3287 30.9557 20.6713 30.0373 21.2618L20.3111 27.5143C19.3129 28.156 18 27.4393 18 26.2525L18 13.7475C18 12.5607 19.3129 11.844 20.3111 12.4857L30.0372 18.7382Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g> */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.0372 18.7382C30.9557 19.3287 30.9557 20.6713 30.0373 21.2618L20.3111 27.5143C19.3129 28.156 18 27.4393 18 26.2525L18 13.7475C18 12.5607 19.3129 11.844 20.3111 12.4857L30.0372 18.7382Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {/* <defs>
        <filter
          id="filter0_f_129_3309"
          x="15.5"
          y="9.74423"
          width="17.7261"
          height="20.5115"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1"
            result="effect1_foregroundBlur_129_3309"
          />
        </filter>
      </defs> */}
    </svg>
  )
}
