import Countdown from 'react-countdown'

import { ENABLE_ARCHIVED_GAMES } from '../../constants/settings'
import {
  GUESS_DISTRIBUTION_TEXT,
  NEW_WORD_TEXT,
  SHARE_TEXT,
  STATISTICS_TITLE,
} from '../../constants/strings'
import { GameStats } from '../../lib/localStorage'
import { solutionGameDate, tomorrow } from '../../lib/melodies'
import { shareStatus } from '../../lib/share'
import { Histogram } from '../stats/Histogram'
import { MigrationIntro } from '../stats/MigrationIntro'
import { StatBar } from '../stats/StatBar'
import { BaseModal } from './BaseModal'
import { LumiPromo } from './LumiPromo'

type Props = {
  isOpen: boolean
  handleClose: () => void
  solution: number[]
  guesses: number[][]
  startedAt: string | null
  completedAt: string | null
  gameStats: GameStats
  isLatestGame: boolean
  isGameLost: boolean
  isGameWon: boolean
  handleShareToClipboard: () => void
  handleShareFailure: () => void
  handleMigrateStatsButton: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
  numberOfGuessesMade: number
}

export const StatsModal = ({
  isOpen,
  handleClose,
  solution,
  guesses,
  startedAt,
  completedAt,
  gameStats,
  isLatestGame,
  isGameLost,
  isGameWon,
  handleShareToClipboard,
  handleShareFailure,
  handleMigrateStatsButton,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
  numberOfGuessesMade,
}: Props) => {
  if (gameStats.totalGames <= 0) {
    return (
      <BaseModal
        title={STATISTICS_TITLE}
        isOpen={isOpen}
        handleClose={handleClose}
        hasTopPaddingOnly={true}
      >
        <StatBar gameStats={gameStats} />
        <LumiPromo />
      </BaseModal>
    )
  }
  return (
    <BaseModal
      title={STATISTICS_TITLE}
      isOpen={isOpen}
      handleClose={handleClose}
      hasTopPaddingOnly={true}
    >
      <div className="px-3">
        <StatBar gameStats={gameStats} />
        <h4 className="text-base font-medium leading-6 md:mt-4">
          {GUESS_DISTRIBUTION_TEXT}
        </h4>
        <Histogram
          isLatestGame={isLatestGame}
          gameStats={gameStats}
          isGameWon={isGameWon}
          isGameLost={isGameLost}
          numberOfGuessesMade={numberOfGuessesMade}
        />
      </div>
      <div className=" items-center justify-center text-center font-light">
        {(isGameLost || isGameWon) && (
          <div className="mx-4 mt-2 border-t border-stats-seperator pt-3 dark:border-dark-stats-seperator sm:mx-8 md:pt-5">
            <div className="inline-block w-full">
              {(!ENABLE_ARCHIVED_GAMES || isLatestGame) && (
                <div>
                  <div className="text-md mb-1">
                    {NEW_WORD_TEXT}{' '}
                    <Countdown
                      className="text-lg"
                      date={tomorrow}
                      daysInHours={true}
                      onComplete={() => {
                        window.location.href = '/'
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="justify-center sm:flex">
              <div className="text-center sm:w-1/2 sm:pl-3">
                <button
                  type="button"
                  className="text-dark-grey dark:bg-blue mt-2 mb-1 inline-flex items-center justify-center rounded-3xl border-2 border-share-button-border px-14 py-2 font-sans text-sm font-medium text-share-button-text shadow-sm transition hover:scale-105 dark:border-dark-share-button-border dark:text-dark-share-button-text sm:mb-2  sm:text-base"
                  onClick={() => {
                    shareStatus(
                      solution,
                      guesses,
                      startedAt,
                      completedAt,
                      isGameLost,
                      isHardMode,
                      isDarkMode,
                      isHighContrastMode,
                      handleShareToClipboard,
                      handleShareFailure
                    )
                  }}
                >
                  {SHARE_TEXT}
                </button>
              </div>
            </div>
          </div>
        )}
        <LumiPromo />
      </div>
    </BaseModal>
  )
}
