import classNames from 'classnames'

type Props = {
  solution: string
  guess: string
  isRevealing?: boolean
  isMainPlayButton?: boolean
}

export const RowContainer = (props: any) => {
  return (
    <div
      className={classNames(
        'rounded-4xl flex w-full items-center justify-between py-1 mdshort:mb-0',
        'dark:bg-dark-row-bg',
        'short:p-1',
        { 'mb-1 bg-sausage  dark:bg-dark-sausage': !props.isMainPlayButton },
        {
          'mb-0 h-12 border-2 border-play-button-active-border dark:border-dark-play-button-active-border md:h-14 short:h-10':
            props.isMainPlayButton,
        }
      )}
    >
      {props.children}
    </div>
  )
}
