export const KeydleLogo = () => {
  return (
    <svg
      width="92"
      height="26"
      viewBox="0 0 92 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="border-1 fill-logo-text dark:fill-dark-logo-text">
        <path d="M17.8533 19.9999L8.1093 10.5359L16.9013 1.3519H12.3933L4.2453 10.1719V1.3519H0.7453V19.9999H4.2453V11.1519L13.0653 19.9999H17.8533Z" />
        <path d="M33.2354 12.7479C33.2354 8.7439 30.2954 5.4399 26.0954 5.4399C22.0074 5.4399 18.7034 8.7439 18.7034 12.8319C18.7034 16.8919 22.0354 20.2239 26.1234 20.2239C28.3074 20.2239 30.4074 19.3839 32.3394 17.2279L30.0714 15.1559C28.7554 16.6119 27.2714 17.1159 26.1234 17.1159C24.3874 17.1159 22.6234 15.7999 22.3994 14.1479H33.1234C33.1234 14.1479 33.2354 13.2799 33.2354 12.7479ZM29.5114 11.1799H22.4274C22.8754 9.6679 24.4154 8.5479 26.0954 8.5479C27.7474 8.5479 29.1194 9.6399 29.5114 11.1799Z" />
        <path d="M49.9404 5.6639H46.1884L42.1284 14.0359L38.0404 5.6639H34.3164L40.2804 17.6759L36.3884 25.6559H40.1124L49.9404 5.6639Z" />
        <path d="M65.6651 19.9999V0.399902H62.305V6.7839C61.269 5.9719 59.9251 5.4399 58.3571 5.4399C54.3811 5.4399 51.133 8.7999 51.133 12.8319C51.133 16.8639 54.3811 20.2239 58.3571 20.2239C59.9251 20.2239 61.269 19.6919 62.305 18.8799V19.9999H65.6651ZM62.305 12.8319C62.305 15.2119 60.6251 17.1159 58.4131 17.1159C56.2011 17.1159 54.5211 15.2119 54.5211 12.8319C54.5211 10.4519 56.2011 8.5479 58.4131 8.5479C60.6251 8.5479 62.305 10.4519 62.305 12.8319Z" />
        <path d="M73.6639 19.9999V0.399902H70.2759V19.9999H73.6639Z" />
        <path d="M91.8057 12.7479C91.8057 8.7439 88.8657 5.4399 84.6657 5.4399C80.5777 5.4399 77.2737 8.7439 77.2737 12.8319C77.2737 16.8919 80.6057 20.2239 84.6937 20.2239C86.8777 20.2239 88.9777 19.3839 90.9097 17.2279L88.6417 15.1559C87.3257 16.6119 85.8417 17.1159 84.6937 17.1159C82.9577 17.1159 81.1937 15.7999 80.9697 14.1479H91.6937C91.6937 14.1479 91.8057 13.2799 91.8057 12.7479ZM88.0817 11.1799H80.9977C81.4457 9.6679 82.9857 8.5479 84.6657 8.5479C86.3177 8.5479 87.6897 9.6399 88.0817 11.1799Z" />
      </g>
    </svg>
  )
}
