import classnames from 'classnames'

type Props = {
  onClick?: () => void
  className: string
}

export const HelpIcon = ({ onClick, className }: Props) => {
  return (
    <div onClick={onClick} className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="fill-nav-icon dark:fill-dark-nav-icon">
          <path d="M29.204 20.632C29.204 17.636 26.404 15.9 23.604 15.9C21.028 15.9 19.404 17.048 18.396 19.064L20.804 20.212C21.448 18.952 22.288 18.448 23.632 18.448C25.088 18.448 26.516 19.344 26.516 20.688C26.516 22.144 25.648 22.928 24.472 23.852C22.176 25.7 21.616 27.156 21.616 30.236H24.332C24.332 27.884 24.836 26.792 26.684 25.336C28.7 23.796 29.204 22.312 29.204 20.632ZM24.78 33.96C24.78 32.98 23.968 32.196 22.988 32.196C22.008 32.196 21.224 32.98 21.224 33.96C21.224 34.94 22.008 35.724 22.988 35.724C23.968 35.724 24.78 34.94 24.78 33.96Z" />
          {/* <g filter="url(#filter0_f_129_4332)">
          <path
          d="M29.204 20.632C29.204 17.636 26.404 15.9 23.604 15.9C21.028 15.9 19.404 17.048 18.396 19.064L20.804 20.212C21.448 18.952 22.288 18.448 23.632 18.448C25.088 18.448 26.516 19.344 26.516 20.688C26.516 22.144 25.648 22.928 24.472 23.852C22.176 25.7 21.616 27.156 21.616 30.236H24.332C24.332 27.884 24.836 26.792 26.684 25.336C28.7 23.796 29.204 22.312 29.204 20.632ZM24.78 33.96C24.78 32.98 23.968 32.196 22.988 32.196C22.008 32.196 21.224 32.98 21.224 33.96C21.224 34.94 22.008 35.724 22.988 35.724C23.968 35.724 24.78 34.94 24.78 33.96Z"
          fill="white"
          />
        </g> */}
        </g>
        {/* <defs>
          <filter
            id="filter0_f_129_4332"
            x="12.9594"
            y="10.4634"
            width="21.6812"
            height="30.6971"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="2.71828"
              result="effect1_foregroundBlur_129_4332"
            />
          </filter>
        </defs> */}
      </svg>
    </div>
  )
}
