import { GameStats } from '../../lib/localStorage'
import { Progress } from './Progress'

type Props = {
  gameStats: GameStats
  isLatestGame: boolean
  isGameWon: boolean
  isGameLost: boolean
  numberOfGuessesMade: number
}

const isCurrentDayStatRow = (
  isLatestGame: boolean,
  isGameWon: boolean,
  numberOfGuessesMade: number,
  i: number
) => {
  return isLatestGame && isGameWon && numberOfGuessesMade === i + 1
}

export const Histogram = ({
  gameStats,
  isLatestGame,
  isGameWon,
  isGameLost,
  numberOfGuessesMade,
}: Props) => {
  const winDistribution = [...gameStats.winDistribution, gameStats.gamesFailed]
  const maxValue = Math.max(...winDistribution, 1)

  return (
    <div className="justify-left m-auto mt-2 max-w-xs columns-7 text-sm">
      {winDistribution.map((value, i, arr) => {
        const isFailedBar = i === arr.length - 1
        return (
          <Progress
            key={i}
            label={isFailedBar ? 'X' : String(i + 1)}
            isCurrentDayStatRow={
              isCurrentDayStatRow(
                isLatestGame,
                isGameWon,
                numberOfGuessesMade,
                i
              ) ||
              (isGameLost && isFailedBar)
            }
            size={90 * (value / maxValue)}
            value={value}
          />
        )
      })}
    </div>
  )
}
