export const RAINBOW_COLORS = [
  '#C20064',
  '#D04058',
  '#DE804B',
  '#E9AD02',
  '#DFCC06',
  '#86C03B',
  '#128044',
  '#2B86A2',
  '#448CFF',
  '#4148DC',
  '#5E4ED3',
  '#9B00E0',
]

export const CORRECT_COLOR = '#00A582'
export const PRESENT_COLOR = '#F5BE27'
export const ABSENT_COLOR = '#EBEBEB'
export const DARK_ABSENT_COLOR = '#0C0C27'
export const KEY_ACTIVE_COLOR = '#FFFFFF'
export const DARK_KEY_ACTIVE_COLOR = '#FFFFFF'

export const KEY_TEXT_DEFAULT_COLOR = '#FFFFFF'
export const DARK_KEY_TEXT_DEFAULT_COLOR = '#FFFFFF'
export const KEY_TEXT_CORRECT_COLOR = '#FFFFFF'
export const KEY_TEXT_PRESENT_COLOR = '#FFFFFF'
export const KEY_TEXT_ABSENT_COLOR = 'rgba(255, 255, 255, 0.7)'
export const DARK_KEY_TEXT_ABSENT_COLOR = 'rgba(255, 255, 255, 0.3)'

export const DEFAULT_BOX_COLOR = '#333636'
export const DEFAULT_BG_COLOR = '#3F4444'
export const DEFAULT_KEYBOARD_NOTE_AVAILABLE_COLOR = '#C3C2CC'
export const DARK_KEYBOARD_NOTE_AVAILABLE_COLOR = '#262548'

export const LUMI_BOX_COLOR = '#23204E'
export const LUMI_BG_COLOR = '#12122D'
export const LUMI_BUTTON_COLOR = '#231D7D'
export const LUMI_KEYBOARD_NOTE_AVAILABLE_COLOR = '#8380C6'
export const LUMI_KEYBOARD_ABSENT_COLOR = '#141245'

export const setOpacity = (hex: string, alpha: number) =>
  `${hex}${Math.floor(alpha * 255)
    .toString(16)
    .padStart(2)}`
