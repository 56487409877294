import {
  LUMI_EXTERNAL_URL_CTA,
  LUMI_EXTERNAL_URL_CTA_LINK,
  LUMI_PROMO_TEXT,
} from '../../constants/strings'

export const LumiPromo = () => {
  return (
    <div className="lumi-promo mt-4 flex justify-start p-4 sm:justify-start sm:bg-center sm:p-5 md:p-6">
      <div className="w-44 max-w-xs text-left text-white sm:w-72">
        <p className="pb-2 text-sm sm:pb-4 sm:text-base">{LUMI_PROMO_TEXT}</p>
        <button
          type="button"
          className="text-dark-grey mt-2 inline-flex rounded-3xl border border-transparent bg-lumi-learn-more-button px-8 py-2 text-sm font-normal text-lumi-learn-more-button-text shadow-sm transition hover:scale-105 sm:text-base"
          onClick={() => {}}
        >
          <a className="no-underline" href={LUMI_EXTERNAL_URL_CTA_LINK}>
            {LUMI_EXTERNAL_URL_CTA}
          </a>
        </button>
      </div>
    </div>
  )
}
