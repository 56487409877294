import './App.css'

import { useCallback, useEffect, useMemo, useState } from 'react'
import ConfettiExplosion from 'react-confetti-explosion'
import Div100vh from 'react-div-100vh'

import { AlertContainer } from './components/alerts/AlertContainer'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { InfoModal } from './components/modals/InfoModal'
import { SettingsModal } from './components/modals/SettingsModal'
import { StatsModal } from './components/modals/StatsModal'
import { Navbar } from './components/navbar/Navbar'
import { RAINBOW_COLORS } from './constants/colors'
import {
  DISCOURAGE_INAPP_BROWSERS,
  LONG_ALERT_TIME_MS,
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  WELCOME_INFO_MODAL_MS,
} from './constants/settings'
import {
  ABSENT_NOTE_HINT,
  CORRECT_WORD_MESSAGE,
  CORRECT_WORD_MESSAGE_AFTER_RETURNING,
  DISCOURAGE_INAPP_BROWSER_TEXT,
  EASY_MODE_ALERT_MESSAGE,
  GAME_COPIED_MESSAGE,
  HARD_MODE_ALERT_MESSAGE,
  SHARE_FAILURE_TEXT,
  WIN_MESSAGES,
} from './constants/strings'
import { useAlert } from './context/AlertContext'
import { getPlaybackRateMs, playCat } from './lib/audio'
import { playNote } from './lib/audio'
import { isInAppBrowser, isInWebAppBrowser } from './lib/browser'
import { isAprilFoolsDay } from './lib/dateutils'
import {
  getStoredIsHighContrastMode,
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsDarkMode,
  setStoredIsHighContrastMode,
  themeKey,
} from './lib/localStorage'
import {
  getIsLatestGame,
  getRandomDate,
  hasSolutionInGuesses,
  isWinningMelody,
  setGameDate,
  solution,
  solutionGameDate,
  solutionToNiceString,
} from './lib/melodies'
import { addStatsForCompletedGame, loadStats } from './lib/stats'

function App() {
  const isLatestGame = getIsLatestGame()
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches
  const isWebApp = isInWebAppBrowser()
  if (isWebApp) {
    document.documentElement.classList.add('web-app')
  }

  const {
    showError: showErrorAlert,
    showSuccess: showSuccessAlert,
    showInfo: showInfoAlert,
  } = useAlert()
  const [currentGuess, setCurrentGuess] = useState<number[]>([])
  const [isGameWon, setIsGameWon] = useState(false)
  const [didFinishGameEarlier, setDidFinishGameEarlier] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [showConfetti, setShowConfetti] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem(themeKey)
      ? localStorage.getItem(themeKey) === 'dark'
      : prefersDarkMode
      ? true
      : false
  )
  const [isHighContrastMode, setIsHighContrastMode] = useState(() => {
    return getStoredIsHighContrastMode()
  })
  const [isRevealing, setIsRevealing] = useState(false)
  const [hasMelodyPlaybackStarted, setHasMelodyPlaybackStarted] =
    useState(false)
  const [hasHeardMelody, setHasHeardMelody] = useState(false)
  const [startedAt, setStartedAt] = useState<string | null>(null)
  const [completedAt, setCompletedAt] = useState<string | null>(null)
  const [guesses, setGuesses] = useState<number[][]>(() => {
    const loaded = loadGameStateFromLocalStorage(isLatestGame)
    if (!loaded) return []
    if (!isWinningMelody(loaded.solution)) {
      // local storage melody is not today's melody so reset game state and timer values
      setStartedAt(null)
      setCompletedAt(null)
      return []
    } else {
      setStartedAt(loaded.startedAt)
      setCompletedAt(loaded.completedAt)
    }

    if (loaded.guesses.length > 0) {
      setHasHeardMelody(true)
      setHasMelodyPlaybackStarted(true)
    }

    const gameWasWon = hasSolutionInGuesses(loaded.guesses)
    if (gameWasWon) {
      setDidFinishGameEarlier(true)
      setIsGameWon(true)
    }
    if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
      setIsGameLost(true)
      setDidFinishGameEarlier(true)
      // showErrorAlert(
      //   CORRECT_WORD_MESSAGE_AFTER_RETURNING(solutionToNiceString(solution)),
      //   {
      //     persist: false,
      //   }
      // )
    }
    return loaded.guesses
  })

  const [stats, setStats] = useState(() => loadStats())

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )
  const [hasPickedHardMode, setHasPickedHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )

  const isAprilFools = false //isAprilFoolsDay(solutionGameDate)

  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal
    if (!loadGameStateFromLocalStorage(true)) {
      setTimeout(() => {
        setIsInfoModalOpen(true)
      }, WELCOME_INFO_MODAL_MS)
    }
  })

  useEffect(() => {
    DISCOURAGE_INAPP_BROWSERS &&
      isInAppBrowser() &&
      showErrorAlert(DISCOURAGE_INAPP_BROWSER_TEXT, {
        persist: false,
        durationMs: 4000,
      })
  }, [showErrorAlert])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleHardMode = (isHard: boolean) => {
    if (isHardMode || hasPickedHardMode) {
      // Make it easy straight away
      setIsHardMode(false)
      setHasPickedHardMode(false)
      localStorage.setItem('gameMode', 'normal')
      if (hasHeardMelody) {
        showInfoAlert(EASY_MODE_ALERT_MESSAGE)
      }
    } else if (hasHeardMelody) {
      // hard mode will be active in the next game
      showInfoAlert(HARD_MODE_ALERT_MESSAGE)
      setHasPickedHardMode(isHard)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
    } else {
      // not hard mode and hasnt started game yet
      setIsHardMode(isHard)
      setHasPickedHardMode(isHard)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
    }
  }

  const handleSetDarkMode = (isLumiMode: boolean) => {
    setIsDarkMode(isLumiMode)
    setStoredIsDarkMode(isLumiMode)
  }

  const handleSetHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  useEffect(() => {
    saveGameStateToLocalStorage(getIsLatestGame(), {
      guesses,
      solution,
      startedAt,
      completedAt,
    })
  }, [guesses, completedAt, startedAt])

  useEffect(() => {
    if (isGameWon && !didFinishGameEarlier) {
      const winMessage =
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]

      const revealTime = getPlaybackRateMs(isHardMode)
      const delayMs = revealTime * solution.length

      showSuccessAlert(winMessage, {
        delayMs,
        onClose: () => setIsStatsModalOpen(true),
      })
    }

    if (isGameLost || didFinishGameEarlier) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, (solution.length + 1) * getPlaybackRateMs(isHardMode) * (didFinishGameEarlier ? 1 : 2))
    }
  }, [
    isHardMode,
    isGameWon,
    didFinishGameEarlier,
    isGameLost,
    showSuccessAlert,
  ])

  const onNewNote = (value: number, status: string) => {
    const shouldDisplayAbsentNoteHint =
      !isGameWon && !isGameLost && !isHardMode && status === 'absent'

    if (shouldDisplayAbsentNoteHint) {
      showInfoAlert(ABSENT_NOTE_HINT)
    }
    if (isAprilFools) {
      playCat(value)
    } else {
      playNote(value)
    }
    if (
      hasHeardMelody &&
      currentGuess.length <= solution.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      const newCurrentGuess = [...currentGuess, value]
      setCurrentGuess(newCurrentGuess)
    }
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onEnter = useCallback(() => {
    if (isGameWon || isGameLost) {
      return
    }

    const revealTime = getPlaybackRateMs(isHardMode)
    setIsRevealing(true)
    setTimeout(() => {
      setIsRevealing(false)
    }, revealTime * solution.length)

    if (
      currentGuess.length === solution.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess([])

      if (isWinningMelody(currentGuess)) {
        if (isLatestGame) {
          setStats(addStatsForCompletedGame(stats, guesses.length))
        }
        setCompletedAt(new Date().toISOString())
        const wasFirstGuess = guesses.length === 0
        setShowConfetti(wasFirstGuess)
        return setIsGameWon(true)
      }

      if (guesses.length === MAX_CHALLENGES - 1) {
        if (isLatestGame) {
          setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        }
        setCompletedAt(new Date().toISOString())
        setIsGameLost(true)
      }
    }
  }, [
    isHardMode,
    currentGuess,
    guesses,
    isGameLost,
    isGameWon,
    isLatestGame,
    stats,
  ])

  useEffect(() => {
    if (currentGuess.length === solution.length) {
      onEnter()
    }
  }, [currentGuess, onEnter])

  const showStartingNote = useMemo(() => {
    return (
      !isHardMode &&
      hasMelodyPlaybackStarted &&
      currentGuess.length === 0 &&
      !(isGameLost || isGameWon)
    )
  }, [
    isHardMode,
    hasMelodyPlaybackStarted,
    currentGuess,
    isGameLost,
    isGameWon,
  ])

  const width = window.innerWidth
  const height = document.body.scrollHeight

  return (
    <Div100vh className="app-container">
      <div className="bg-dark-bg dark:bg-dark-bg flex h-full flex-col">
        <Navbar
          setIsInfoModalOpen={setIsInfoModalOpen}
          setIsStatsModalOpen={setIsStatsModalOpen}
          setIsSettingsModalOpen={setIsSettingsModalOpen}
        />
        <div className="flex justify-center">
          {showConfetti && (
            <div className="">
              <ConfettiExplosion
                height={height}
                width={width}
                colors={RAINBOW_COLORS}
                particleSize={15}
              />
            </div>
          )}
        </div>

        <div className="grid-keys-wrapper mx-auto flex w-full grow flex-col px-1 pt-2 pb-2 sm:px-6 sm:pb-4 md:max-w-7xl md:pb-8 lg:px-8 short:pb-2 short:pt-2">
          <div className="flex grow flex-col justify-center pb-6 short:pb-2">
            <Grid
              solution={solution}
              guesses={guesses}
              currentGuess={currentGuess}
              isRevealing={isRevealing}
              currentRowClassName={currentRowClass}
              showStartingNote={showStartingNote}
              isDarkMode={isDarkMode}
              isHardMode={isHardMode}
              isGameLost={isGameLost}
              didFinishGameEarlier={didFinishGameEarlier}
              hasHeardMelody={hasHeardMelody}
              onMelodyPlaybackStarted={() => {
                setHasMelodyPlaybackStarted(true)
              }}
              onMelodyPlaybackEnded={() => {
                setHasHeardMelody(true)
                if (!startedAt) {
                  setStartedAt(new Date().toISOString())
                }
              }}
            />
          </div>
          <Keyboard
            onNewNote={onNewNote}
            onDelete={onDelete}
            onEnter={onEnter}
            showStartingNote={showStartingNote}
            solution={solution}
            guesses={guesses}
            isDisabled={isRevealing}
            isDarkMode={isDarkMode}
          />
          <InfoModal
            isDarkMode={isDarkMode}
            isOpen={isInfoModalOpen}
            handleClose={() => setIsInfoModalOpen(false)}
          />
          <StatsModal
            isOpen={isStatsModalOpen}
            handleClose={() => setIsStatsModalOpen(false)}
            solution={solution}
            guesses={guesses}
            startedAt={startedAt}
            completedAt={completedAt}
            gameStats={stats}
            isLatestGame={isLatestGame}
            isGameLost={isGameLost}
            isGameWon={isGameWon}
            handleShareToClipboard={() => showInfoAlert(GAME_COPIED_MESSAGE)}
            handleShareFailure={() =>
              showErrorAlert(SHARE_FAILURE_TEXT, {
                durationMs: LONG_ALERT_TIME_MS,
              })
            }
            handleMigrateStatsButton={() => {
              setIsStatsModalOpen(false)
            }}
            isHardMode={isHardMode}
            isDarkMode={isDarkMode}
            isHighContrastMode={isHighContrastMode}
            numberOfGuessesMade={guesses.length}
          />
          {/* <DatePickerModal
            isOpen={isDatePickerModalOpen}
            initialDate={solutionGameDate}
            handleSelectDate={(d) => {
              setIsDatePickerModalOpen(false)
              setGameDate(d)
            }}
            handleClose={() => setIsDatePickerModalOpen(false)}
          /> */}
          {/* <MigrateStatsModal
            isOpen={isMigrateStatsModalOpen}
            handleClose={() => setIsMigrateStatsModalOpen(false)}
          /> */}
          <SettingsModal
            isOpen={isSettingsModalOpen}
            handleClose={() => setIsSettingsModalOpen(false)}
            isHardMode={isHardMode}
            hasPickedHardMode={hasPickedHardMode}
            handleHardMode={handleHardMode}
            isHighContrastMode={isHighContrastMode}
            handleHighContrastMode={handleSetHighContrastMode}
            isDarkMode={isDarkMode}
            handleDarkMode={handleSetDarkMode}
            handleOnNewMelody={() => {
              const date = getRandomDate(
                new Date(2022, 0, 1),
                new Date(2023, 0, 1)
              )
              setGameDate(date)
            }}
          />
          <AlertContainer />
        </div>
      </div>
    </Div100vh>
  )
}

export default App
