import {
  addDays,
  differenceInDays,
  formatISO,
  parseISO,
  startOfDay,
} from 'date-fns'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import queryString from 'query-string'

import { MELODIES } from '../constants/melodyList'
import { ENABLE_ARCHIVED_GAMES } from '../constants/settings'
import { NOT_CONTAINED_MESSAGE, WRONG_SPOT_MESSAGE } from '../constants/strings'
import { NOTES } from './audio'
import { getToday } from './dateutils'
import { getGuessStatuses } from './statuses'

// 1 January 2022 Game Epoch
export const firstGameDate = new Date(2023, 1, 11)
export const periodInDays = 1

export const isWinningMelody = (melody: number[]) => {
  if (!Array.isArray(melody)) return false
  for (let i = 0; i < solution.length; i++) {
    if (solution[i] !== melody[i]) return false
  }
  return true
}

export const hasSolutionInGuesses = (guesses: number[][]) => {
  for (let guess of guesses) {
    if (isWinningMelody(guess)) return true
  }
  return false
}

export const getRandomDate = (from: Date, to: Date) => {
  const fromTime = from.getTime()
  const toTime = to.getTime()
  return new Date(fromTime + Math.random() * (toTime - fromTime))
}

// build a set of previously revealed letters - present and correct
// guess must use correct letters in that space and any other revealed letters
// also check if all revealed instances of a letter are used (i.e. two C's)
export const findFirstUnusedReveal = (
  melody: number[],
  guesses: number[][]
) => {
  if (guesses.length === 0) {
    return false
  }

  console.log(1)

  const tonesLeftInArray = new Array<number>()
  const guess = guesses[guesses.length - 1]
  const statuses = getGuessStatuses(solution, guess)
  // const splitWord = unicodeSplit(word)
  // const splitGuess = unicodeSplit(guess)

  for (let i = 0; i < melody.length; i++) {
    if (statuses[i] === 'correct' || statuses[i] === 'present') {
      console.log(2)
      tonesLeftInArray.push(guess[i])
    }
    if (statuses[i] === 'correct' && melody[i] !== guess[i]) {
      console.log(3)
      return WRONG_SPOT_MESSAGE(NOTES[guess[i]], i + 1)
    }
  }

  // check for the first unused letter, taking duplicate letters
  // into account - see issue #198
  let n
  for (const noteIndex of melody) {
    console.log(4)
    n = tonesLeftInArray.indexOf(noteIndex)
    if (n !== -1) {
      console.log(5)
      tonesLeftInArray.splice(n, 1)
    }
  }

  if (tonesLeftInArray.length > 0) {
    console.log(6)
    return NOT_CONTAINED_MESSAGE(NOTES[tonesLeftInArray[0]])
  }
  return false
}

export const unicodeSplit = (word: string) => {
  return new GraphemeSplitter().splitGraphemes(word)
}

export const unicodeLength = (word: string) => {
  return unicodeSplit(word).length
}

export const localeAwareLowerCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleLowerCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toLowerCase()
}

export const localeAwareUpperCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleUpperCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toUpperCase()
}

export const getLastGameDate = (today: Date) => {
  const t = startOfDay(today)
  let daysSinceLastGame = differenceInDays(firstGameDate, t) % periodInDays
  return addDays(t, -daysSinceLastGame)
}

export const getNextGameDate = (today: Date) => {
  return addDays(getLastGameDate(today), periodInDays)
}

export const isValidGameDate = (date: Date) => {
  if (date < firstGameDate || date > getToday()) {
    return false
  }

  return differenceInDays(firstGameDate, date) % periodInDays === 0
}

export const getIndex = (gameDate: Date) => {
  let start = firstGameDate
  let index = -1
  do {
    index++
    start = addDays(start, periodInDays)
  } while (start <= gameDate)

  return index
}

export const getMelodyOfTheDay = (index: number) => {
  if (index < 0) {
    throw new Error('Invalid index')
  }

  return MELODIES[index % MELODIES.length]
}

export const getSolution = (gameDate: Date) => {
  const nextGameDate = getNextGameDate(gameDate)
  const index = getIndex(gameDate)
  const melodyOfTheDay = getMelodyOfTheDay(index)
  return {
    solution: melodyOfTheDay,
    solutionGameDate: gameDate,
    solutionIndex: index,
    tomorrow: nextGameDate.valueOf(),
  }
}

export const solutionToNiceString = (solution: number[]) => {
  return solution.map((noteIndex) => NOTES[noteIndex]).join(' ')
}

export const getGameDate = () => {
  if (getIsLatestGame()) {
    return getToday()
  }

  const parsed = queryString.parse(window.location.search)
  try {
    const d = startOfDay(parseISO(parsed.d!.toString()))
    if (d >= getToday() || d < firstGameDate) {
      setGameDate(getToday())
    }
    return d
  } catch (e) {
    console.log(e)
    return getToday()
  }
}

export const setGameDate = (d: Date) => {
  try {
    if (d < getToday()) {
      window.location.href = '/?d=' + formatISO(d, { representation: 'date' })
      return
    }
  } catch (e) {
    console.log(e)
  }
  window.location.href = '/'
}

export const getIsLatestGame = () => {
  if (!ENABLE_ARCHIVED_GAMES) {
    return true
  }
  const parsed = queryString.parse(window.location.search)
  return parsed === null || !('d' in parsed)
}

export const { solution, solutionGameDate, solutionIndex, tomorrow } =
  getSolution(getGameDate())
