import { Midi, Sampler, Sequence, Synth, Transport } from 'tone'

export const NOTES = [
  'C',
  'D♭',
  'D',
  'E♭',
  'E',
  'F',
  'G♭',
  'G',
  'A♭',
  'A',
  'B♭',
  'B',
]
export const NOTE_INDEXES: Record<string, number> = {
  C: 0,
  'D♭': 1,
  D: 2,
  'E♭': 3,
  E: 4,
  F: 5,
  'G♭': 6,
  G: 7,
  'A♭': 8,
  A: 9,
  'B♭': 10,
  B: 11,
}

export const BLACK_NOTES = [1, 3, 6, 8, 10]
export const isWhiteNote = (note: number) => !BLACK_NOTES.includes(note)

export const MIDDLE_C = 60

let callbacks: any = []

const clearActiveOnEndedCallbacks = () => {
  for (const cb of callbacks) {
    cb()
  }
  callbacks = []
}

const SYNTH_SETTINGS = {
  envelope: {
    attack: 0.02,
  },
}

export const playMelody = (
  noteIndexes: number[],
  playbackRate: number,
  onEnded: () => void
) => {
  const notes = noteIndexes.map((noteIndex) => {
    const note = Midi(noteIndex + MIDDLE_C).toNote()
    return note
  })
  const notesWithEndMarker = [...notes, 'END']
  Transport.stop()
  Transport.cancel(0)

  clearActiveOnEndedCallbacks()

  callbacks.push(onEnded)
  const synth = new Synth(SYNTH_SETTINGS).toDestination()

  const seq = new Sequence((time, note) => {
    if (note === 'END') {
      clearActiveOnEndedCallbacks()
      return
    }
    synth.triggerAttackRelease(note, 0.15, time)
    // subdivisions are given as subarrays
  }, notesWithEndMarker).start(0)
  seq.loop = false
  seq.playbackRate = playbackRate
  Transport.start()
}

export const playNote = (noteIndex: number) => {
  const synth = new Synth(SYNTH_SETTINGS).toDestination()
  const note = Midi(noteIndex + MIDDLE_C).toNote()
  synth.triggerAttackRelease(note, '12n')
}

var sampler = new Sampler(
  {
    D4: 'cat.ogg',
  },
  () => {
    console.log('loaded sampler')
  },
  'audio/'
).toDestination()

export const playCat = (noteIndex: number) => {
  const note = Midi(noteIndex + MIDDLE_C).toNote()
  sampler.triggerAttackRelease(note, '4n')
}

// TODO: work this out using playback rate settings and master bpm
export const getPlaybackRateMs = (isHardMode: boolean) =>
  isHardMode ? 300 : 400
