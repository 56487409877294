import { NOTES } from '../lib/audio'

export type CharStatus = 'absent' | 'present' | 'correct'

export const getStatuses = (
  solution: number[],
  guesses: number[][]
): { [key: string]: CharStatus } => {
  const charObj: { [key: string]: CharStatus } = {}

  guesses.forEach((melody) => {
    melody.forEach((noteIndex, i) => {
      if (!solution.includes(noteIndex)) {
        // make status absent
        return (charObj[NOTES[noteIndex]] = 'absent')
      }

      if (noteIndex === solution[i]) {
        //make status correct
        return (charObj[NOTES[noteIndex]] = 'correct')
      }

      if (charObj[NOTES[noteIndex]] !== 'correct') {
        //make status present
        return (charObj[NOTES[noteIndex]] = 'present')
      }
    })
  })

  return charObj
}

export const getGuessStatuses = (
  solution: number[],
  guess: number[]
): CharStatus[] => {
  const solutionNotesTaken = solution.map((_) => false)

  const statuses: CharStatus[] = Array.from(Array(guess.length))

  // handle all correct cases first
  guess.forEach((noteIndex, i) => {
    if (noteIndex === solution[i]) {
      statuses[i] = 'correct'
      solutionNotesTaken[i] = true
      return
    }
  })

  guess.forEach((noteIndex, i) => {
    if (statuses[i]) return

    if (!solution.includes(noteIndex)) {
      // handles the absent case
      statuses[i] = 'absent'
      return
    }

    // now we are left with "present"s
    const indexOfPresentNote = solution.findIndex(
      (x, index) => x === noteIndex && !solutionNotesTaken[index]
    )

    if (indexOfPresentNote > -1) {
      statuses[i] = 'present'
      solutionNotesTaken[indexOfPresentNote] = true
      return
    } else {
      statuses[i] = 'absent'
      return
    }
  })

  return statuses
}
