import classNames from 'classnames'

type Props = {
  value: number
  size: number
  label: string
  isCurrentDayStatRow: boolean
}

export const Progress = ({
  value,
  size,
  label,
  isCurrentDayStatRow,
}: Props) => {
  const currentRowClass = classNames(
    'text-xs font-medium text-center bg-stats-chart-bar-default dark:bg-dark-stats-chart-bar-default rounded-2xl w-7',
    {
      'bg-stats-chart-bar-today dark:bg-dark-stats-chart-bar-today text-stats-chart-bar-today-text dark:text-dark-stats-chart-bar-today-text':
        isCurrentDayStatRow,
      'text-stats-chart-bar-default-text dark:text-dark-stats-chart-bar-default-text':
        !isCurrentDayStatRow,
      'bg-light-grey': !isCurrentDayStatRow,
      'pt-1': Math.floor(size) > 0,
      'pt-0': Math.floor(size) === 0,
    }
  )
  return (
    <div className="flex h-32 flex-col md:h-36">
      <div className="flex h-full flex-col items-center justify-end">
        <div style={{ height: `${10 + size}%` }} className={currentRowClass}>
          {String(value)}
        </div>
        <div className="mt-1">{label}</div>
      </div>
    </div>
  )
}
